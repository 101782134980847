import React, { FC } from "react";
import { useHookstate } from "@hookstate/core";
import { SignInForm } from "./SignInForm";
import useUser from "../../hooks/useUser";
import { PasswordResetForm } from "./PasswordResetForm";
import SignedInAs from "./SignedInAs";

enum UIMode {
    Default,
    SignIn,
    PasswordReset
}

const SignIn: FC = () => {
    const user = useUser();

    const mode = useHookstate(UIMode.Default);

    switch (mode.get()) {
    case UIMode.SignIn:
        return (
            <SignInForm
                onSuccess={newUser => {
                    user.update(newUser);
                    mode.set(UIMode.Default);
                }}
                onCancel={() => mode.set(UIMode.Default)}
                onPasswordReset={() => mode.set(UIMode.PasswordReset)} />
        );

    case UIMode.PasswordReset:
        return (
            <PasswordResetForm
                onDone={() => mode.set(UIMode.SignIn)} />
        );
    }

    return (
        <SignedInAs
            onSignIn={() => mode.set(UIMode.SignIn)} />
    );
};

export default SignIn;
