import React, { FC, useCallback } from "react";
import { Box, Image, BoxProps, Flex, Tooltip, useColorMode, useToast } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { COVER_ASPECT_RATIO } from "../../constants";
import useLocalization from "../../hooks/useLocalization";
import { COVER_HEIGHT } from "./Book";
import uploadIconLightMode from "../../../assets/upload-icon-light-mode.svg";
import uploadIconDarkMode from "../../../assets/upload-icon-dark-mode.svg";
import { State, useHookstate } from "@hookstate/core";
import { bookshelfState, editState, uploadState } from "../../state";
import { IBookshelf } from "../../types";
import useUser from "../../hooks/useUser";
import { waitForConversion } from "./polling";
import useApi from "../../hooks/useApi";
import { getFile } from "../../utils";

const baseBoxProps: BoxProps = {
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderRadius: "2px",
    cursor: "pointer",
    transition: "color .3s, border-color .3s"
};

const darkBoxProps: BoxProps = {
    color: "rgba(255, 255, 255, 0.25)",
    borderColor: "rgba(255, 255, 255, 0.25)",
    _hover: {
        color: "rgba(255, 255, 255, 0.5)",
        borderColor: "rgba(255, 255, 255, 0.5)"
    }
};

const lightBoxProps: BoxProps = {
    color: "rgba(0, 0, 0, 0.25)",
    borderColor: "rgba(0, 0, 0, 0.25)",
    _hover: {
        color: "rgba(0, 0, 0, 0.5)",
        borderColor: "rgba(0, 0, 0, 0.5)"
    }
};

interface IProps {
    shelf: State<IBookshelf>;
}

const NewBook: FC<IProps> = (props: IProps) => {
    const w = COVER_HEIGHT * COVER_ASPECT_RATIO;
    const h = COVER_HEIGHT;

    const { colorMode } = useColorMode();
    const { uiText } = useLocalization();
    const user = useUser();

    const toast = useToast();

    const upload = useHookstate(uploadState);
    const shelfState = useHookstate(bookshelfState);
    const edited = useHookstate(editState);

    const api = useApi();

    const errorToast = (error: any) => toast({
        title: "Error",
        description: String(error),
        isClosable: true,
        status: "error"
    });

    const onDrop = useCallback(acceptedFiles => {
        const file = getFile(acceptedFiles, () => {
            errorToast(uiText.get("upload.tooManyFiles.description"));
        });

        if (!file) return;

        upload.file.set(file);
        upload.name.set(null);
        upload.totalSize.set(undefined);
        upload.transferred.set(undefined);
        upload.status.set("Uploading");

        api.material().uploadMaterial(acceptedFiles[0], (current, total) => {
            upload.totalSize.set(total);
            upload.transferred.set(current);
        })
            .then(material => {
                upload.materialId.set(material.id);
                upload.status.set(material.status);

                waitForConversion(user.get(), material, upload).then(m => {
                    if (m == null) {
                        errorToast("Technical issue: Material missing.");
                    }
                    else {
                        api.book().addBook(props.shelf.id.get(), m).then(book => {
                            upload.file.set(undefined);

                            api.shelf().getShelves().then(shelves => {
                                shelfState.shelves.set(shelves);
                            });

                            edited.book.set(book);
                        });
                    }
                });
            })
            .catch((error) => {
                errorToast(error);
                upload.status.set("NotInProgress");
            });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const uploadIcon = colorMode == "dark" ? uploadIconDarkMode : uploadIconLightMode;

    const boxProps = {
        ...baseBoxProps,
        ...(colorMode == "dark" ? darkBoxProps : lightBoxProps),
        w, h
    };

    return (
        <Box>
            <Flex {...boxProps} {...getRootProps()}>
                <input {...getInputProps()} />
                <Tooltip label={uiText.get("bookshelf.new.tooltip")}>
                    <Box fontSize={150}>
                        {isDragActive ? (
                            <Image
                                src={uploadIcon}
                                w="70px" />
                        ) : "+"}
                    </Box>
                </Tooltip>
            </Flex>
        </Box>
    );
};

export default NewBook;
