import { AlertStatus, useToast } from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { getUIDictionary } from "../apiProxy/ui";
import { currentUserState, uiDictionaryState } from "../state";
import { UIText } from "../UIText";
import { getLocale } from "../utils";

export interface ILocalizationHook {
    ready: () => boolean;
    uiText: UIText;
    uiToast: (status: AlertStatus, titleKey: string, descriptionKey: string) => void;
    errorToast: (error: any) => void;
    reload: () => void;
}

export default function (): ILocalizationHook {
    const chakraToast = useToast();
    const uiDictionary = useHookstate(uiDictionaryState);
    const currentUser = useHookstate(currentUserState);

    function ready(): boolean {
        return (uiDictionary["#ready"].get() == "true");
    }

    function reload(): void {
        const locale = getLocale(currentUser.get());

        getUIDictionary(locale).then(dictionary => {
            if (dictionary) {
                uiDictionary.set(dictionary);
            }
        });
    }

    const uiText = new UIText(uiDictionary.get());

    return {
        ready,
        uiText,
        uiToast: (status: AlertStatus, titleKey: string, descriptionKey: string) => chakraToast({
            title: uiText.get(titleKey),
            description: uiText.get(descriptionKey),
            isClosable: true,
            status
        }),
        errorToast: (error: any) => chakraToast({
            title: "Error",
            description: String(error),
            isClosable: true,
            status: "error"
        }),
        reload
    };
}
