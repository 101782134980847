import { ShelfAPI } from "../../../apiProxy/shelf";
import { IBookshelf, IModifyBookshelf } from "../../../types";

export class ShelfOperator {
    constructor(
        private readonly api: ShelfAPI,
        private readonly shelves: IBookshelf[],
        private readonly refreshCallback: () => void
    ) {
    }

    async add(name: string, isPublic: boolean) {
        await this.api.addShelf(name, isPublic);
        this.refreshCallback();
    }

    async move(index: number, targetIndex: number, beforeTarget: boolean) {
        const data: IModifyBookshelf = {
            position: {
                targetShelveId: this.shelves[targetIndex].id,
                beforeTarget
            }
        };

        await this.modify(index, data);
        this.refreshCallback();
    }

    async modify(index: number, data: IModifyBookshelf) {
        await this.api.modifyShelf(this.shelves[index].id, data);
        this.refreshCallback();
    }

    async delete(index: number) {
        const id = this.shelves[index].id;
        await this.api.deleteShelf(id);
        this.refreshCallback();
    }
}
