import React, { FC } from "react";
import { Flex } from "@chakra-ui/react";
import { IParent } from "../types";

interface IProps extends IParent<React.ReactNode> {
}

export const MainLayout: FC<IProps> = (props: IProps) => (
    <Flex direction="column">
        {props.children}
    </Flex>
);
