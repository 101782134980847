import { FormControl, FormLabel, Textarea, Text } from "@chakra-ui/react";
import { State } from "@hookstate/core";
import React, { FC } from "react";
import useLocalization from "../../hooks/useLocalization";
import { IBookEditState } from "../../types";
import config from "../../../config";

interface IProps {
  state: State<IBookEditState>;
}

const EditDescription: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const { state } = props;

    const descAtLimit = state.description.get().length >= config.descriptionMaxChars;

    return (
        <>
            <FormControl mt="20px">
                <FormLabel htmlFor="description">
                    {uiText.get("pub-edit-dialog.pub.description.label")}
                </FormLabel>
                <Textarea
                    id="description"
                    onChange={(e) => state.description.set(e.target.value)}
                    value={state.description.get()}
                    maxLength={config.descriptionMaxChars}
                    placeholder={uiText.get(
                        "pub-edit-dialog.pub.description.placeholder"
                    )}
                />
            </FormControl>

            <Text
                fontSize="xs"
                color={descAtLimit ? "red.400" : "inherit"}
                opacity={descAtLimit ? 1 : .5}
                textAlign="right"
            >
                {uiText.get("pub-edit-dialog.pub.description.charsUsed", {
                    used: state.description.get().length,
                    max: config.descriptionMaxChars
                })}
            </Text>
        </>
    );
};

export default EditDescription;
