import React, { FC } from "react";
import {
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from "@chakra-ui/react";
import useLocalization from "../../hooks/useLocalization";
import GeneralTab from "./Tabs/GeneralTab";
import BookshelvesTab from "./Tabs/BookshelvesTab";
import FooterTab from "./Tabs/FooterTab";

const TabContainer: FC = () => {
    const { uiText } = useLocalization();

    return (
        <Tabs>
            <TabList>
                <Tab>{uiText.get("admin.tabs.general")}</Tab>
                <Tab>{uiText.get("admin.tabs.bookshelves")}</Tab>
                <Tab>{uiText.get("admin.tabs.footer")}</Tab>
            </TabList>

            <TabPanels>
                <TabPanel>
                    <GeneralTab />
                </TabPanel>
                <TabPanel>
                    <BookshelvesTab />
                </TabPanel>
                <TabPanel>
                    <FooterTab />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default TabContainer;
