import React, { FC } from "react";

interface IProps {
    w: string;
    h: string;
    front: React.ReactNode;
    back: React.ReactNode;
    flipSpeed?: string;
}

const FlippingBox: FC<IProps> = (props: IProps) => {
    return (
        <div
            className="flipping-box"
            style={{
                width: props.w,
                height: props.h,
            }}
        >
            <div className="flipping-box-inner" style={{
                transition: `transform ${props.flipSpeed || "0.8s"}`
            }}>
                <div className="flipping-box-front">
                    {props.front}
                </div>
                <div className="flipping-box-back">
                    {props.back}
                </div>
            </div>
        </div>
    );
};

export default FlippingBox;
