import configData from "../../config";
import { MAX_SEARCH_RESULTS, MAX_SUBRESULTS } from "../constants";
import { IBookshelf, ISearchResults } from "../types";
import { APIBase } from "./APIBase";

export class SearchAPI extends APIBase {
    search(shelves: IBookshelf[], term: string): Promise<ISearchResults> {
        let url = configData.timeStoryAPIRootUrl + configData.search.baseUrl;

        return new Promise((resolve, reject) => {
            this.post(url, {
                shelveId: null,
                term,
                termToResults: true,
                booksTake: MAX_SEARCH_RESULTS,
                booksSkip: 0,
                maxResultsPerMaterial: MAX_SUBRESULTS
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch(e => reject(e));
        });
    }
}
