import React, { FC } from "react";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { useState } from "@hookstate/core";
import useLocalization from "../../../hooks/useLocalization";
import { bookshelfState, metaState } from "../../../state";
import { IBookInShelf, IBookshelf } from "../../../types";

interface IProps {
    onChange: (bookId: string) => void;
}

const LiftBookSelector: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const meta = useState(metaState);
    const state = useState(bookshelfState);

    return (
        <FormControl>
            <FormLabel htmlFor="lift-book">
                {uiText.get("admin.general.hero.lift-book.label")}
            </FormLabel>
            <Select
                placeholder="(not selected)"
                onChange={e => props.onChange(e.target.value)}
                value={meta.collection["hero.lift-book-id"].get() || ""}
            >
                {getAllPublicBooks(state.shelves.get()).map(item => (
                    <option key={item.book.id} value={String(item.book.id)}>
                        {item.shelf.name} / {item.book.name}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
};

function getAllPublicBooks(shelves: IBookshelf[]): IBookInShelf[] {
    let books: IBookInShelf[] = [];

    for (let shelf of shelves.filter(x => x.isPublic)) {
        books = books.concat(shelf.books.filter(x => x.isPublic).map(book => ({
            book,
            shelf
        })));
    }

    return books;
}

export default LiftBookSelector;
