import React, { FC } from "react";
import { Button, useDisclosure } from "@chakra-ui/react";
import Confirm from "../Confirm";
import useLocalization from "../../hooks/useLocalization";

interface IProps {
    onReset: () => void;
}

const ResetCoverWithConfirm: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const dialog = useDisclosure();

    return (
        <>
            <Button
                onClick={dialog.onOpen}
                variant="ghost"
                size="xs"
            >
                {uiText.get("pub-edit-dialog.pub.coverResetButton")}
            </Button>

            <Confirm
                onConfirm={props.onReset}
                isOpen={dialog.isOpen}
                onClose={dialog.onClose}
                title="Reset Cover Image"
                content={"This operation will overwrite the current cover image " +
                    "with the default image. Are you sure?"}
                confirmButtonText="Yes, reset" />
        </>
    );
};

export default ResetCoverWithConfirm;
