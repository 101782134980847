import React, { FC, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { LayoutSection } from "../../layouts/LayoutSection";
import { SearchBox } from "./SearchBox";
import { SearchResults } from "./SearchResults";
import { useHookstate } from "@hookstate/core";
import { useSearchParams } from "react-router-dom";
import { searchState } from "../../state";

enum UIMode {
    Default,
    Results
}

const Search: FC = () => {
    const [search, setSearch] = useSearchParams();
    const q = search.get("q") || "";

    const query = useHookstate(searchState);
    const mode = useHookstate<UIMode>(UIMode.Default);

    const execute = (q: string) => {
        query.set(q);
        mode.set(UIMode.Results);
    };

    useEffect(() => {
        if (q) {
            execute(q);
        }
    }, [q]);

    return (
        <LayoutSection>
            <Box
                my="50px"
                display={["block", "none"]}
            >
                <SearchBox
                    onSearch={q => {
                        setSearch({ q });
                        execute(q);
                    }}
                    onReset={() => {
                        query.set("");
                        setSearch({ q: "" });
                        mode.set(UIMode.Default);
                    }} />
            </Box>

            <Box mt={[0, "80px"]}>
                {mode.get() == UIMode.Results ?
                    <SearchResults
                        query={q} /> : null}
            </Box>
        </LayoutSection>
    );
};

export default Search;
