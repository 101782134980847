import React, { FC } from "react";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { LogoLeft } from "./LogoLeft";
import { HamburgerMenu } from "./HamburgerMenu";
import { LayoutSection } from "../../layouts/LayoutSection";
import Search from "./Search";
import { metaState } from "../../state";
import { useState } from "@hookstate/core";

const Header: FC = () => {
    const meta = useState(metaState);

    const logoUrl = useColorModeValue(
        meta.collection["header.logo.light"].get(),
        meta.collection["header.logo.dark"].get()
    );

    return (
        <Box h="70px">
            <LayoutSection fixed>
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    height="70px"
                >
                    <LogoLeft url={logoUrl} />
                    <Box display={["none", "block"]}>
                        <Search />
                    </Box>
                    <HamburgerMenu />
                </Flex>
            </LayoutSection>
        </Box>
    );
};

export default Header;
