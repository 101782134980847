export default {
    "cyan": {
        "100": "#1A9779",
    },
    "blue": {
        "50": "#ECF2F8",
        "100": "#CBD9EB",
        "200": "#AAC1DF",
        "300": "#88A9D2",
        "400": "#6790C6",
        "500": "#4678B9",
        "600": "#386094",
        "700": "#2A486F",
        "800": "#1C304A",
        "900": "#0E1825"
    },
    "teal": {
        "50": "#E9FBF7",
        "100": "#C2F5E8",
        "200": "#9BEED9",
        "300": "#74E7CA",
        "400": "#4DE0BC",
        "500": "#25DAAD",
        "600": "#1EAE8A",
        "700": "#168368",
        "800": "#0F5745",
        "900": "#072C23"
    },
    "maguerite": {
        "50": "#EEEDF8",
        "100": "#CFCBEB",
        "200": "#B0AADF",
        "300": "#9189D2",
        "400": "#7368C5",
        "500": "#5446B9",
        "600": "#433894",
        "700": "#322A6F",
        "800": "#221C4A",
        "900": "#110E25"
    },
    "purple": {
        "50": "#F0E7FE",
        "100": "#D5BBFB",
        "200": "#BA90F9",
        "300": "#9F64F7",
        "400": "#8338F4",
        "500": "#680DF2",
        "600": "#530AC2",
        "700": "#3F0891",
        "800": "#2A0561",
        "900": "#150330"
    },
    "yellow": {
        "50": "#FFFAE5",
        "100": "#FFF0B8",
        "200": "#FFE68A",
        "300": "#FFDC5C",
        "400": "#FFD32E",
        "500": "#FFC900",
        "600": "#CCA100",
        "700": "#997900",
        "800": "#665000",
        "900": "#332800"
    }
};
