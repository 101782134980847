import React, { FC } from "react";
import { Button, Heading, Text } from "@chakra-ui/react";
import useLocalization from "../../hooks/useLocalization";
import useUser from "../../hooks/useUser";

interface IProps {
    onSignIn: () => void;
}

const SignedInAs: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const user = useUser();

    if (user.isSignedIn())
    {
        return (
            <>
                <Text>
                    {uiText.get("signIn.signedInAs", {
                        user: user.get().email ||  "<unknown>"
                    })}
                </Text>

                <Button
                    onClick={() => user.clear()}
                    mt="20px"
                    w="100%"
                    variant="outline"
                >
                    {uiText.get("signIn.signOut")}
                </Button>
            </>
        );
    }

    return (
        <>
            <Heading as="h3" size="sm">
                {uiText.get("signIn.heading")}
            </Heading>

            <Button
                onClick={props.onSignIn}
                w="100%"
                mt="12px"
            >
                {uiText.get("signIn.button")}
            </Button>
        </>
    );
};

export default SignedInAs;
