import React, { FC } from "react";
import { Container, Box, Flex, Link, Stack } from "@chakra-ui/react";
import { Links } from "./Links";

const Footer: FC = () => (
    <Container py={getStyles("40px", "90px")}>
        <Flex display={["block", "block", "flex"]}>
            <Box w={getStyles("100%", "30%")}>
                <Stack>
                    <Link href="https://sales.timestory.io" isExternal>
                        TimeStory.io
                    </Link>
                    <Link
                        href="https://www.timehouse.fi/en"
                        isExternal
                        textTransform="uppercase"
                        fontSize="xs">
                            Powered by Timehouse
                    </Link>
                </Stack>
            </Box>

            <Box
                ml={getStyles(0, "200px")}
                mt={getStyles("60px", 0)}
            >
                <Links />
            </Box>
        </Flex>
    </Container>
);

type styleProp = string|number;

function getStyles(narrow: styleProp, wide: styleProp) {
    return [narrow, narrow, wide];
}

export default Footer;
