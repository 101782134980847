import React, { FC } from "react";
import DialogWrapper from "./DialogWrapper";
import TabContainer from "./TabContainer";

interface IProps {
  onClose: () => void;
}

const AdministrationDialog: FC<IProps> = (props: IProps) => {
    return (
        <DialogWrapper
            onClose={props.onClose}
        >
            <TabContainer />
        </DialogWrapper>
    );
};

export default AdministrationDialog;
