import React, { FC, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer
} from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { IModifyBookshelf } from "../../../types";
import DeleteWithConfirm from "./DeleteWithConfirm";
import useLocalization from "../../../hooks/useLocalization";

interface IProps {
    title: string;
    allowDelete?: boolean;
    editedBookshelf?: IModifyBookshelf;
    isOpen: boolean;
    onClose: () => void;
    onSave: (data: IModifyBookshelf) => void;
    onDelete: () => void;
}

const DEFAULT_MODEL: IModifyBookshelf = {
    name: ""
};

const EditShelfDialog: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const model = useHookstate({ ...DEFAULT_MODEL });

    useEffect(() => {
        if (props.editedBookshelf) {
            model.set({ ...props.editedBookshelf });
        }
        else {
            model.set({ ...DEFAULT_MODEL });
        }
    }, [props.editedBookshelf?.name]);

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl my="20px">
                        <FormLabel htmlFor="name">
                            {uiText.get("admin.bookshelves.edit.name.label")}
                        </FormLabel>

                        <Input
                            id="name"
                            type="text"
                            placeholder={"Name of your bookshelf"}
                            value={model.name.get()}
                            onChange={e => model.name.set(e.target.value)} />
                    </FormControl>

                    <FormControl my="20px">
                        <Checkbox
                            isChecked={model.isPublic.get()}
                            onChange={e => model.isPublic.set(e.target.checked)}
                        >
                            {uiText.get("admin.bookshelves.edit.public.label")}
                        </Checkbox>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Flex w="100%">
                        <Box>
                            {props.allowDelete ? (
                                <DeleteWithConfirm
                                    onDelete={() => {
                                        props.onDelete();
                                        props.onClose();
                                        model.set({ ...DEFAULT_MODEL });
                                    }} />
                            ) : null}
                        </Box>
                        <Spacer />
                        <Box>
                            <Button
                                disabled={(model.name.get() || "").trim().length == 0}
                                mr={3}
                                onClick={() => {
                                    props.onSave(model.get());
                                    props.onClose();
                                    model.set({ ...DEFAULT_MODEL });
                                }}
                            >
                                Save
                            </Button>
                            <Button variant="ghost" onClick={props.onClose}>
                                Cancel
                            </Button>
                        </Box>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default EditShelfDialog;
