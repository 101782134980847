import { BookAPI } from "../apiProxy/book";
import { MaterialAPI } from "../apiProxy/material";
import { SearchAPI } from "../apiProxy/search";
import { ShelfAPI } from "../apiProxy/shelf";
import useUser from "./useUser";

export interface IAPIHook {
    shelf: () => ShelfAPI;
    book: () => BookAPI;
    material: () => MaterialAPI;
    search: () => SearchAPI;
}

export default function (): IAPIHook {
    const user = useUser();

    return {
        shelf: () => new ShelfAPI(user.get()),
        book: () => new BookAPI(user.get()),
        material: () => new MaterialAPI(user.get()),
        search: () => new SearchAPI(user.get())
    };
}
