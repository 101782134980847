import React, { FC } from "react";
import { Box, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";
import { useState } from "@hookstate/core";
import { MetaAPI } from "../../../apiProxy/meta";
import useLocalization from "../../../hooks/useLocalization";
import useUser from "../../../hooks/useUser";
import { metaState } from "../../../state";
import { InstanceMetaKey } from "../../../types";
import LiftBookSelector from "./LiftBookSelector";

let timer: ReturnType<typeof setTimeout>;

const GeneralTab: FC = () => {
    const { uiText } = useLocalization();
    const user = useUser();
    const meta = useState(metaState);

    const handleChange = (key: InstanceMetaKey, value: string) => {
        meta.collection[key].set(value);

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            const api = new MetaAPI(user.get());
            api.setMeta(key, value);
        }, 1000);
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
            }}
            style={{
                marginBottom: "20px",
            }}
        >
            <FormControl mt="20px">
                <FormLabel htmlFor="hero-title">
                    {uiText.get("admin.general.hero.title.label")}
                </FormLabel>
                <Input
                    id="hero-title"
                    onChange={e => handleChange("hero.title", e.target.value)}
                    value={meta.collection["hero.title"].get() || ""}
                    maxLength={30}
                    placeholder={uiText.get("admin.general.hero.title.placeholder")}
                />
            </FormControl>

            <FormControl mt="20px">
                <FormLabel htmlFor="hero-lead">
                    {uiText.get("admin.general.hero.lead.label")}
                </FormLabel>
                <Textarea
                    id="hero-lead"
                    onChange={e => handleChange("hero.lead", e.target.value)}
                    value={meta.collection["hero.lead"].get() || ""}
                    maxLength={250}
                    placeholder={uiText.get(
                        "admin.general.hero.lead.placeholder"
                    )}
                />
            </FormControl>

            <Box mt="20px">
                <LiftBookSelector
                    onChange={bookId => handleChange("hero.lift-book-id", bookId)} />
            </Box>
        </form>
    );
};

export default GeneralTab;
