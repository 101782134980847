import { Heading } from "@chakra-ui/react";
import React, { FC } from "react";
import { IParent } from "../../types";

type Props = IParent<string>;

const Bookshelf: FC<Props> = (props: Props) => {
    return (
        <Heading as="h2">
            {props.children}
        </Heading>
    );
};

export default Bookshelf;
