import React, { FC, RefObject } from "react";
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    useDisclosure
} from "@chakra-ui/react";
import { IBook, IBookEditState } from "../../types";
import { State, useState } from "@hookstate/core";
import EditTitle from "./EditTitle";
import EditDescription from "./EditDescription";
import useLocalization from "../../hooks/useLocalization";
import { BookPropReader } from "../BookPropReader";
import { COVER_ASPECT_RATIO } from "../../constants";
import CoverEditor from "./CoverEditor";
import useApi from "../../hooks/useApi";
import config from "../../../config";
import ResetCoverWithConfirm from "./ResetCoverWithConfirm";

const COVER_HEIGHT = 172;
const COVER_WIDTH = COVER_HEIGHT * COVER_ASPECT_RATIO;

interface IProps {
  state: State<IBookEditState>;
  book: IBook;
  initialFocusRef?: RefObject<HTMLInputElement>;
  onBookChanged: () => void;
}

const BasicInformation: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const { state, initialFocusRef } = props;

    const api = useApi();

    const book = new BookPropReader(props.book);
    const coverUrl = useState(book.getCoverImageUrl());

    const coverEditor = useDisclosure();

    return (
        <Flex>
            <Box flexGrow={1}>
                <EditTitle
                    state={state}
                    initialFocusRef={initialFocusRef} />

                <EditDescription state={state} />

                <FormControl>
                    <Checkbox
                        isChecked={state.isPublic.get()}
                        onChange={e => state.isPublic.set(e.target.checked)}
                    >
                        {uiText.get("pub-edit-dialog.pub.public.label")}
                    </Checkbox>
                </FormControl>
            </Box>

            <Box ml={10}>
                <FormLabel htmlFor="title">
                    {uiText.get("pub-edit-dialog.pub.cover.label")}
                </FormLabel>

                <Box
                    w={`${COVER_WIDTH}px`}
                    h={`${COVER_HEIGHT}px`}
                    backgroundImage={coverUrl.get()}
                    backgroundPosition="50% 50%"
                    backgroundSize="cover" />

                <Box mt={2}>
                    <Button
                        onClick={coverEditor.onOpen}
                        variant="outline"
                        size="xs"
                        mr={1}
                    >
                        {uiText.get("pub-edit-dialog.pub.coverEditButton")}
                    </Button>

                    <ResetCoverWithConfirm
                        onReset={async () => {
                            const relativeUrl = await api.book().deleteCoverImage(props.book.id);
                            coverUrl.set(config.coverImageUrlPrefix + relativeUrl);
                            props.onBookChanged();
                        }}/>
                </Box>
            </Box>

            <CoverEditor
                book={props.book}
                isOpen={coverEditor.isOpen}
                onImageChange={url => {
                    coverUrl.set(url);
                    props.onBookChanged();
                }}
                onDone={url => {
                    coverUrl.set(url);
                    props.onBookChanged();
                    coverEditor.onClose();
                }}
                onCancel={coverEditor.onClose} />
        </Flex>
    );
};

export default BasicInformation;
