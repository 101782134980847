import { AxiosResponse } from "axios";
import { HTTP_OK, LOCALE_PRECEDENCE } from "./constants";
import { IFile, IUser } from "./types";

export function inBrowser(): boolean {
    return typeof window != "undefined";
}

export function getLocale(user: IUser|null = null): string {
    const localeGetters: (() => string|null)[] = [
        getUserLocaleGetter(user),
        getSessionLocale,
        getBrowserLocale
    ];

    for (const localeGetter of localeGetters) {
        const locale = localeGetter();
        if (locale) {
            return locale;
        }
    }

    return LOCALE_PRECEDENCE[0];
}

function getUserLocaleGetter(user: IUser|null): () => string|null {
    return () => {
        if (user?.locale && isSupportedLocale(user.locale)) {
            return user.locale;
        }
        return null;
    };
}

export function setSessionLocale(locale: string): void {
    if (!inBrowser()) {
        return;
    }

    if (LOCALE_PRECEDENCE.indexOf(locale) == -1) {
        throw new Error("Unsupported locale.");
    }

    sessionStorage.setItem("locale", locale);
}

export function getSessionLocale(): string|null {
    if (inBrowser()) {
        const locale = sessionStorage.getItem("locale");
        if (isSupportedLocale(locale)) {
            return locale;
        }
    }
    return null;
}

function getBrowserLocale(): string|null {
    let locale: string|null = null;

    if (inBrowser()) {
        locale = LOCALE_PRECEDENCE.find(x =>
            window.navigator.languages.indexOf(x) != -1
        ) || null;
    }

    return locale;
}

function isSupportedLocale(anyLocale: string|null): boolean {
    return LOCALE_PRECEDENCE.indexOf(anyLocale || "") != -1;
}

export function axiosResponseIsJson(response: AxiosResponse): boolean {
    return response.status == HTTP_OK &&
        response.headers["content-type"] == "application/json";
}

export function capitalize(s: string): string {
    return s[0].toUpperCase() + s.slice(1);
}

export function shuffleArray<T>(array: T[]): void {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export function humanFileSize(
    bytes: number,
    dp = 1,
    units: string[] = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
) {
    const THRESH = 1024;

    if (Math.abs(bytes) < THRESH) {
        return bytes + units[0];
    }

    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= THRESH;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= THRESH && u < units.length - 1);


    return bytes.toFixed(dp) + " " + units[u + 1];
}

export function cloneObject<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function toQueryString(obj: any) {
    var str = [];
    for (let p in obj) {
        if (Object.hasOwnProperty.call(obj, p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    }
    return str.join("&");
}

export function getFile(acceptedFiles: any, tooManyFiles: () => void): IFile|undefined {
    const files = acceptedFiles.map((x: any) => {
        const file: IFile = {
            name: x.name,
            type: x.type,
            size: x.size
        };

        return file;
    });

    if (files.length > 1) {
        tooManyFiles();
    }
    else if (files != 0) {
        return files[0];
    }

    return undefined;
}
