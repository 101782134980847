import React, { FC } from "react";
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    IconButton,
    Stack,
    Text
} from "@chakra-ui/react";
import { IBookshelf } from "../../../types";
import ReorderButton from "./ReorderButton";
import { EditIcon } from "@chakra-ui/icons";
import BookElement from "./BookElement";
import { useState } from "@hookstate/core";
import { bookshelfState } from "../../../state";
import useApi from "../../../hooks/useApi";
import useLocalization from "../../../hooks/useLocalization";
import { NOT_PUBLIC_OPACITY } from "../../../constants";

interface IProps {
    bookshelf: IBookshelf;
    allowUp: boolean;
    allowDown: boolean;
    onMoveUp: () => void;
    onMoveDown: () => void;
    onEdit: () => void;
}

const BookshelfElement: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const api = useApi();
    const state = useState(bookshelfState);

    const refresh = async () => {
        const shelves = await api.shelf().getShelves();
        state.shelves.set(shelves);
    };

    return (
        <AccordionItem>
            <AccordionButton as="div">
                <Flex pr="12px" gap="8px">
                    <ReorderButton
                        type="up"
                        disabled={!props.allowUp}
                        onClick={() => props.onMoveUp()} />
                    <ReorderButton
                        type="down"
                        disabled={!props.allowDown}
                        onClick={() => props.onMoveDown()} />
                </Flex>

                <Box flex={1} textAlign="left" fontSize="lg">
                    <Text as="span" opacity={props.bookshelf.isPublic ? 1 : NOT_PUBLIC_OPACITY}>
                        {props.bookshelf.name}
                    </Text>
                    <IconButton
                        onClick={e => {
                            e.preventDefault();
                            props.onEdit();
                        }}
                        variant="ghost"
                        size="sm"
                        aria-label="Edit"
                        icon={<EditIcon />} />
                </Box>

                <AccordionIcon />
            </AccordionButton>

            <AccordionPanel>
                {props.bookshelf.books.length == 0 ? (
                    <Text fontSize="sm">
                        {uiText.get("admin.bookshelves.empty")}
                    </Text>
                ) : (
                    <Stack ml="20px">
                        {props.bookshelf.books.map((book, index) => (
                            <BookElement
                                key={book.id}
                                book={book}
                                allowUp={index > 0}
                                onMoveUp={() => {
                                    api.shelf().putBook({
                                        shelfId: props.bookshelf.id,
                                        bookId: book.id,
                                        siblingBookId: props.bookshelf.books[index - 1].id,
                                        before: true
                                    }).then(refresh);
                                }}
                                allowDown={index < props.bookshelf.books.length - 1}
                                onMoveDown={() => {
                                    api.shelf().putBook({
                                        shelfId: props.bookshelf.id,
                                        bookId: book.id,
                                        siblingBookId: props.bookshelf.books[index + 1].id,
                                        before: false
                                    }).then(refresh);
                                }} />
                        ))}
                    </Stack>
                )}
            </AccordionPanel>
        </AccordionItem>
    );
};

export default BookshelfElement;
