import React, { createRef, FC } from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import useLocalization from "../../hooks/useLocalization";
import { State } from "@hookstate/core";

interface IProps {
    state: State<string>;
    disabled: boolean;
    inError: boolean;
    focusRef?: ReturnType<typeof createRef<HTMLInputElement>>;
}

export const UsernameInput: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();

    return (
        <FormControl isInvalid={props.inError}>
            <FormLabel htmlFor="username">
                {uiText.get("signIn.form.username.label")}
            </FormLabel>

            <Input
                disabled={props.disabled}
                ref={props.focusRef}
                id="username"
                type="email"
                placeholder={uiText.get("signIn.form.username.placeholder")}
                value={props.state.get()}
                onChange={e => props.state.set(e.target.value)} />
        </FormControl>
    );
};
