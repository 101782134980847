import React, { createRef, FC, useEffect } from "react";
import { useState } from "@hookstate/core";
import { IBook, IBookEditState } from "../../types";
import BasicInformation from "./BasicInformation";
import DialogWrapper from "./DialogWrapper";
import { BookPropReader } from "../BookPropReader";

interface IProps {
  book?: IBook;
  onBookChanged: () => void;
  onSave: (edits: IBookEditState) => void;
  onCancel: () => void;
  onDelete: () => void;
}

const EditBookDialog: FC<IProps> = (props: IProps) => {
    const initialFocusRef = createRef<HTMLInputElement>();

    const state = useState<IBookEditState>({
        name: "",
        description: "",
        isPublic: false
    });

    useEffect(() => {
        if (props.book) {
            state.set({
                name: book.getName(),
                description: book.getDescription(),
                isPublic: book.isPublic()
            });
        }
    }, [props.book?.id]);

    if (!props.book) {
        return null;
    }

    const book = new BookPropReader(props.book);

    return (
        <DialogWrapper
            book={props.book}
            onSave={() => {
                if (!props.book) return;
                props.onSave(state.get());
            }}
            onCancel={props.onCancel}
            onDelete={() => props.onDelete()}
            initialFocusRef={initialFocusRef}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
                style={{
                    marginBottom: "20px",
                }}
            >
                <BasicInformation
                    state={state}
                    initialFocusRef={initialFocusRef}
                    book={props.book}
                    onBookChanged={props.onBookChanged} />
            </form>
        </DialogWrapper>
    );
};

export default EditBookDialog;
