import React, { FC, createRef, useEffect } from "react";
import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer
} from "@chakra-ui/react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { COVER_ASPECT_RATIO } from "../../constants";
import UploadCoverImage from "./UploadCoverImage";
import { useState } from "@hookstate/core";
import { IBook, ICropSpec } from "../../types";
import { BookPropReader } from "../BookPropReader";
import useApi from "../../hooks/useApi";
import useLocalization from "../../hooks/useLocalization";
import config from "../../../config";
import { getCompleteCoverImageUrl } from "../../cover";

const DEFAULT_CROP: Crop = {
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    unit: "%"
};

interface IProps {
    book: IBook;
    isOpen: boolean;
    onImageChange: (url: string) => void;
    onDone: (url: string) => void;
    onCancel: () => void;
}

const CoverEditor: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const api = useApi();
    const book = new BookPropReader(props.book);

    const imgRef = createRef<HTMLImageElement>();

    const crop = useState<Crop>(DEFAULT_CROP);

    const url = useState(book.getCoverImageUrl(true));

    useEffect(() => {
        api.book().getCropSpec(props.book.id).then(spec => {
            if (spec) {
                crop.set(mapCropSpecToCropState(spec));
            }
            else {
                crop.set(getDefaultCrop(imgRef));
            }
        });
    }, [imgRef.current, url.get()]);

    return (
        <Modal isOpen={props.isOpen} onClose={props.onCancel}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {uiText.get("cover-edit-dialog.title")}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex justifyContent="center">
                        <Box>
                            <ReactCrop ruleOfThirds
                                aspect={COVER_ASPECT_RATIO}
                                crop={crop.get()}
                                onChange={x => crop.set(x)}
                            >
                                <img
                                    onLoad={() => {
                                        crop.set(getDefaultCrop(imgRef));
                                    }}
                                    src={url.get()}
                                    ref={imgRef} />
                            </ReactCrop>
                        </Box>
                    </Flex>
                </ModalBody>

                <ModalFooter>
                    <Flex w="100%">
                        <UploadCoverImage
                            onCoverChanged={_url => {
                                const completeUrl = config.coverImageUrlPrefix + _url;
                                url.set(completeUrl);
                                props.onImageChange(completeUrl);
                            }}
                            book={props.book} />

                        <Spacer />

                        <Box>
                            <Button
                                mr={3}
                                onClick={() => {
                                    if (!imgRef.current) return;

                                    const w = imgRef.current.width;
                                    const h = imgRef.current.height;

                                    const spec = {
                                        x: crop.x.get() / w,
                                        y: crop.y.get() / h,
                                        size: {
                                            width: crop.width.get() / w,
                                            height: crop.height.get() / h
                                        }
                                    };

                                    api.book().setCropSpec(props.book.id, spec).then(url => {
                                        props.onDone(
                                            getCompleteCoverImageUrl(url)
                                        );
                                    });
                                }}
                            >
                                {uiText.get("cover-edit-dialog.doneButton")}
                            </Button>
                            <Button variant="ghost" onClick={props.onCancel}>
                                {uiText.get("cover-edit-dialog.cancelButton")}
                            </Button>
                        </Box>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

function getDefaultCrop(
    imgRef: ReturnType<typeof createRef<HTMLImageElement>>
): Crop {
    if (!imgRef.current) {
        return DEFAULT_CROP;
    }

    let width = 100, height = 100;
    const imgAspectRatio = imgRef.current.width / imgRef.current.height;

    if (imgAspectRatio < 1) {
        height = 100 / COVER_ASPECT_RATIO * imgAspectRatio;
    }
    else {
        width = 100 * COVER_ASPECT_RATIO / imgAspectRatio;
    }

    return {
        width,
        height,
        x: (100 - width) / 2,
        y: 0,
        unit: "%"
    };
}

function mapCropSpecToCropState(spec: ICropSpec): Crop {
    return {
        x: spec.x * 100,
        y: spec.y * 100,
        width: spec.size.width * 100,
        height: spec.size.height * 100,
        unit: "%"
    };
}

export default CoverEditor;
