export const LOCALE_PRECEDENCE = ["en"];

export const HTTP_OK = 200;
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const HTTP_NOT_FOUND = 404;

export const COVER_ASPECT_RATIO = 0.75;

export const FOOTER_LINK_COUNT = 4;

export const NOT_PUBLIC_OPACITY = .33;

export const MAX_SEARCH_RESULTS = 20;
export const MAX_SUBRESULTS = 3;

export const IMAGE_BOX_NAME = "BookCover";
