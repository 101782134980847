import React, { FC } from "react";
import { Box, BoxProps, Heading, IconButton, Image, Link, Text } from "@chakra-ui/react";
import { IBook } from "../../types";
import { COVER_ASPECT_RATIO, NOT_PUBLIC_OPACITY } from "../../constants";
import FlippingBox from "../FlippingBox";
import editIcon from "../../../assets/edit-icon-white.svg";
import useLocalization from "../../hooks/useLocalization";
import ForAdmins from "../ForAdmins";
import { State, useState } from "@hookstate/core";
import { BookPropReader } from "../BookPropReader";
import { editState } from "../../state";
import { cloneObject } from "../../utils";

export const COVER_HEIGHT = 240;
const W = COVER_HEIGHT * COVER_ASPECT_RATIO;

interface IProps {
    state: State<IBook>;
}

const boxProps: BoxProps = {
    w: "100%",
    h: "100%",
    backgroundColor: "maguerite.800",
    border: "solid white 1px",
    borderRadius: "2px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 4px 12px"
};

const Book: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();

    const { state } = props;
    const w = W, h = COVER_HEIGHT;

    const edited = useState(editState);

    const book = new BookPropReader(props.state.get());

    return (
        <Box w={w} cursor="pointer" opacity={book.isPublic() ? 1 : NOT_PUBLIC_OPACITY}>
            <FlippingBox
                w={`${w}px`}
                h={`${h}px`}
                flipSpeed="0.6s"
                front={(
                    <Link
                        href={book.getReadUrl()}
                        isExternal
                    >
                        <Box {...boxProps}
                            backgroundImage={`url(${book.getCoverImageUrl()})`}
                            backgroundSize="cover" />
                    </Link>
                )}
                back={(
                    <Box {...boxProps}
                        p="8px"
                        overflow="hidden"
                        position="relative"
                    >
                        <Link
                            href={book.getReadUrl()}
                            isExternal
                        >
                            <Box
                                position="absolute"
                                bottom="0"
                                right="0"
                                left="0"
                                top="0"
                                p="10px"
                            >
                                <Text fontSize="sm" color="white">
                                    {book.getDescription()}
                                </Text>
                            </Box>
                        </Link>

                        <ForAdmins>
                            <Box
                                position="absolute"
                                bottom="8px"
                                right="8px"
                            >
                                <IconButton
                                    onClick={() => {
                                        edited.book.set(cloneObject(state.get()));
                                    }}
                                    icon={<Image src={editIcon} />}
                                    backgroundColor="purple.700"
                                    aria-label={uiText.get("bookshelf.edit.label")} />
                            </Box>
                        </ForAdmins>
                    </Box>
                )}
            />

            <Link
                href={book.getReadUrl()}
                isExternal
                style={{ textDecoration: "none" }}
            >
                <Heading as="h3" size="sm" mt="8px" overflow="hidden" height="40px">
                    {state.name.get()}
                </Heading>
            </Link>
        </Box>
    );
};

export default Book;
