import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface IProps {
    url: string;
}

export const LogoLeft: FC<IProps> = (props: IProps) => (
    <Link to={"/"}>
        <Box
            width="140px"
            height="50px"
            p="8px"
            borderRadius="2px"
        >
            <Box
                width="100%"
                height="100%"
                backgroundImage={`url(${props.url})`}
                backgroundRepeat="no-repeat"
                backgroundSize="contain"
                backgroundPosition="center"
                textAlign="center" />
        </Box>
    </Link>
);
