import { IBook, ICropSpec, IMaterial, IModifyBook } from "../types";
import configData from "../../config";
import { APIBase } from "./APIBase";
import { getHeaders } from "./utils";
import axios from "axios";

export class BookAPI extends APIBase {
    getBook(bookId: number): Promise<IBook> {
        const url = configData.timeStoryAPIRootUrl + configData.book.baseUrl +
            `/${bookId}`;

        return new Promise((resolve, reject) => {
            this.get(url)
                .then((response) => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    addBook(shelfId: number, material: IMaterial): Promise<IBook> {
        const url = configData.timeStoryAPIRootUrl + configData.shelf.baseUrl +
            `/${shelfId}${configData.shelf.book}`;

        return new Promise((resolve, reject) => {
            this.post(url, {
                name: material.name,
                materialId: material.id,
                meta: []
            })
                .then((response) => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    modifyBook(bookId: number, data: IModifyBook): Promise<IBook> {
        const url = configData.timeStoryAPIRootUrl +
            configData.book.baseUrl +
            "/" + bookId;

        return new Promise((resolve, reject) => {
            this.put(url, data)
                .then((response) => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    setCoverImage(bookId: number, file: any): Promise<string>
    {
        let data = new FormData();
        data.append("file", file);

        let url = configData.timeStoryAPIRootUrl +
            configData.book.baseUrl +
            "/" + bookId +
            configData.book.coverImage;

        return new Promise((resolve, reject) => {
            axios({
                method: "put",
                url,
                data,
                headers: getHeaders(this.user)
            })
                .then((response) => resolve(response.data))
                .catch((e: any) => reject(e));
        });
    }

    deleteCoverImage(bookId: number): Promise<string>
    {
        let url = configData.timeStoryAPIRootUrl +
            configData.book.baseUrl +
            "/" + bookId +
            configData.book.coverImage;

        return new Promise((resolve, reject) => {
            this.delete(url)
                .then((response) => resolve(response.data))
                .catch((e: any) => reject(e));
        });
    }

    setCropSpec(bookId: number, spec: ICropSpec): Promise<string> {
        let url = configData.timeStoryAPIRootUrl +
            configData.book.baseUrl +
            "/" + bookId +
            configData.book.coverImageCrop;

        return new Promise((resolve, reject) => {
            this.put(url, spec)
                .then((response) => resolve(response.data))
                .catch((e: any) => reject(e));
        });
    }

    getCropSpec(bookId: number): Promise<ICropSpec|undefined> {
        let url = configData.timeStoryAPIRootUrl +
            configData.book.baseUrl +
            "/" + bookId +
            configData.book.coverImageCrop;

        return new Promise((resolve, reject) => {
            this.get(url)
                .then((response) => resolve(response.data))
                .catch((e: any) => reject(e));
        });
    }

    deleteBook(bookId: number): Promise<void> {
        const url = configData.timeStoryAPIRootUrl + configData.book.baseUrl +
            `/${bookId}`;

        return new Promise((resolve, reject) => {
            this.delete(url)
                .then(() => resolve())
                .catch(error => reject(error));
        });
    }
}
