const prodConfig = {
    "timeStoryAPIRootUrl": "https://timestory.io/Api/v2",
    "coverImageUrlPrefix": "https://timestory.io",
    "authentication": {
        "loginUrl": "/Authenticate/Login"
    },
    "shelf": {
        "baseUrl": "/Shelves",
        "book": "/Book"
    },
    "book": {
        "baseUrl": "/Books",
        "coverImage": "/CoverImage",
        "coverImageCrop": "/CoverImageCrop"
    },
    "meta": {
        "baseUrl": "/Meta",
        "imageBox": "/ImageBoxes"
    },
    "search": {
        "baseUrl": "/Search"
    },
    "materials": {
        "baseUrl": "/Materials"
    },
    "timePub": {
        "readUrl": "https://timestory.io/read/DatabaseReader_v3.aspx"
    },
    "descriptionMaxChars": 200
};

const devConfig : typeof prodConfig = {
    ...prodConfig,
    "timeStoryAPIRootUrl": "https://timestorytest.thqa.fi/Api/v2",
    "coverImageUrlPrefix": "https://timestorytest.thqa.fi"
};

// eslint-disable-next-line no-undef
export default process.env.NODE_ENV === "production" ? prodConfig : devConfig;
