import React, { FC } from "react";
import { Box, useToast } from "@chakra-ui/react";
import BookshelfHeading from "./BookshelfHeading";
import UploadProgress from "./UploadProgress";
import EditBookDialog from "../EditBookDialog";
import { State, useHookstate } from "@hookstate/core";
import { bookshelfState, editState } from "../../state";
import { IBook, IBookEditState, IBookshelf, IMeta, IModifyBook, IModifyMeta } from "../../types";
import useUser from "../../hooks/useUser";
import BookContainer from "./BookContainer";
import useLocalization from "../../hooks/useLocalization";
import useApi from "../../hooks/useApi";
import { cloneObject } from "../../utils";

interface IProps {
    state: State<IBookshelf>;
}

const Bookshelf: FC<IProps> = (props: IProps) => {
    const toast = useToast();
    const api = useApi();
    const user = useUser();
    const { uiText } = useLocalization();
    const edited = useHookstate(editState);
    const shelfState = useHookstate(bookshelfState);

    if (props.state.books.length == 0 && !user.isSignedIn()) {
        return null;
    }

    const refresh = () => api.shelf().getShelves().then(shelves => {
        shelfState.shelves.set(shelves);
    });

    const postEditOperation = (action: (book: IBook) => void) => {
        const book = edited.book.get();
        if (!book) return;

        action(cloneObject(book));

        edited.book.set(undefined);
    };

    return (
        <Box mb={12}>
            <BookshelfHeading>{props.state.name.get()}</BookshelfHeading>

            <Box mt="20px">
                <BookContainer shelf={props.state} />
            </Box>

            <EditBookDialog
                book={edited.book.get()}
                onBookChanged={refresh}
                onSave={edits => {
                    postEditOperation(book => {
                        const model = getModifyBookModel(book, edits);

                        api.book().modifyBook(book.id, model).then(() => {
                            refresh().then(() => (toast({
                                title: model.name,
                                description: uiText.get("bookshelf.saveToast.text"),
                                isClosable: true,
                                status: "success"
                            })));
                        });
                    });
                }}
                onCancel={() => {
                    edited.book.set(undefined);
                }}
                onDelete={() => {
                    postEditOperation(book => {
                        api.book().deleteBook(book.id).then(() => {
                            refresh().then(() => (toast({
                                title: book.name,
                                description: uiText.get("bookshelf.deleteToast.text"),
                                isClosable: true,
                                status: "success"
                            })));
                        });
                    });
                }} />

            <UploadProgress />
        </Box>
    );
};

function getModifyBookModel(original: IBook, edits: IBookEditState): IModifyBook {
    let data: IModifyBook = {
        name: edits.name,
        isPublic: edits.isPublic,
        materialId: original.material.id,
        meta: getModifyMetaModel(original.meta, {
            description: edits.description
        })
    };

    return data;
}

function getModifyMetaModel(original: IMeta[], patch: { [key: string]: string }): IModifyMeta[] {
    let data: IModifyMeta[] = [];

    for (let meta of original) {
        let { value } = meta;

        if (typeof patch[meta.key] !== "undefined") {
            value = patch[meta.key];
            delete patch[meta.key];
        }

        data.push({
            key: meta.key,
            value
        });
    }

    for (let key in patch) {
        data.push({
            key,
            value: patch[key]
        });
    }

    return data;
}

export default Bookshelf;
