import {
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td
} from "@chakra-ui/react";
import { useState } from "@hookstate/core";
import React, { FC } from "react";
import { getFooterLinkMetaKey, getFooterLinks } from "../../../footer";
import useLocalization from "../../../hooks/useLocalization";
import { metaState } from "../../../state";
import { InstanceMetaKey } from "../../../types";
import EditLinkDialog from "./EditLinkDialog";

const FooterTab: FC = () => {
    const { uiText } = useLocalization();
    const meta = useState(metaState);
    const editedKey = useState<InstanceMetaKey|undefined>(undefined);

    return (
        <>
            <TableContainer>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th isNumeric>#</Th>
                            <Th>{uiText.get("admin.footer.title")}</Th>
                            <Th>{uiText.get("admin.footer.url")}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {getFooterLinks(meta).map((link, index) => (
                            <Tr
                                onClick={() => {
                                    editedKey.set(getFooterLinkMetaKey(index));
                                }}
                                key={index}
                                cursor="pointer"
                            >
                                <Td isNumeric>{index + 1}.</Td>
                                <Td>{link.title || uiText.get("admin.footer.empty")}</Td>
                                <Td>{link.url || uiText.get("admin.footer.empty")}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>

            <EditLinkDialog
                metaKey={editedKey.get()}
                onDone={() => editedKey.set(undefined)} />
        </>
    );
};

export default FooterTab;
