import React, { FC } from "react";
import {
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    useColorMode
} from "@chakra-ui/react";
import useLocalization from "../../hooks/useLocalization";

export const ColorModeSelector: FC = () => {
    const { uiText } = useLocalization();
    const { colorMode, setColorMode } = useColorMode();

    return (
        <FormControl>
            <FormLabel htmlFor="color-mode">
                {uiText.get("settings.colorMode")}
            </FormLabel>
            <RadioGroup
                onChange={value => {
                    setColorMode(value);
                }}
                value={colorMode}
                id="color-mode"
            >
                <Stack>
                    <Radio value="light">{uiText.get("settings.colorMode.light")}</Radio>
                    <Radio value="dark">{uiText.get("settings.colorMode.dark")}</Radio>
                </Stack>
            </RadioGroup>
        </FormControl>
    );
};
