import { getCoverImageUrl } from "../cover";
import { IBook } from "../types";

export class BookPropReader {
    constructor(private readonly book: IBook) {}

    getName(): string {
        return this.book.name || "";
    }

    getCoverImageUrl(original = false) {
        return getCoverImageUrl(this.book, original);
    }

    getReadUrl() {
        return this.book.material.publicationURL;
    }

    getDescription(): string {
        return this.getMeta("description") || "";
    }

    isPublic(): boolean {
        return this.book.isPublic;
    }

    getMeta(key: string): string|null {
        for (let meta of this.book.meta) {
            if (meta.key === key) {
                return meta.value;
            }
        }
        return null;
    }
}
