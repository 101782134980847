import { State } from "@hookstate/core";
import { FOOTER_LINK_COUNT } from "./constants";
import { IMetaState, IFooterLink, InstanceMetaKey } from "./types";

export function getFooterLinkMetaKey(index: number): InstanceMetaKey {
    if (index < 0 || index > FOOTER_LINK_COUNT - 1) {
        throw new Error("Argument out of range.");
    }
    return `footer.links[${index}]` as InstanceMetaKey;
}

export function getFooterLinks(meta: State<IMetaState>): IFooterLink[] {
    return [...Array(FOOTER_LINK_COUNT).keys()].map(index => {
        const value = meta.collection[getFooterLinkMetaKey(index)].get();

        if (value) {
            return JSON.parse(value);
        }

        return { title: "", url: "" };
    });
}
