import React, { FC } from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
    w: number;
    h: number;
    url: string;
}

export const Cover: FC<IProps> = (props: IProps) => {
    return (
        <Box
            w={`${props.w}px`}
            h={`${props.h}px`}
            backgroundColor="maguerite.200"
            backgroundImage={`url(${props.url})`}
            backgroundSize="99% 99%"
            border="solid rgba(255, 255, 255, .5) 1px"
            borderRadius={2}
            boxShadow="rgba(149, 157, 165, 0.2) 0px 4px 12px" />
    );
};
