import React, { FC } from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import logoLight from "../../../assets/timestory_logo_light.svg";
import logoDark from "../../../assets/timestory_logo_dark.svg";

export const ProductLogo: FC = () => {
    var { colorMode } = useColorMode();
    var url = colorMode == "dark" ? logoDark : logoLight;

    return (
        <Box
            // oikean reunan logo piilotettu
            display="none"

            width="80px"
            height="40px"
            backgroundImage={`url(${url})`}
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            backgroundPosition="center"
            textAlign="center" />
    );
};
