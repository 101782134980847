import React, { FC, useCallback } from "react";
import { AddIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { getFile } from "../../utils";
import useLocalization from "../../hooks/useLocalization";
import useApi from "../../hooks/useApi";
import { IBook } from "../../types";

interface IProps {
    book: IBook;
    onCoverChanged: (url: string) => void;
}

const UploadCoverImage: FC<IProps> = (props: IProps) => {
    const { uiText, errorToast } = useLocalization();
    const api = useApi();

    const onDrop = useCallback(acceptedFiles => {
        const file = getFile(acceptedFiles, () => {
            errorToast(uiText.get("upload.tooManyFiles.description"));
        });

        if (!file) return;

        api.book().setCoverImage(props.book.id, acceptedFiles[0])
            .then((url) => {
                props.onCoverChanged(url);
            });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: { "image/*": [".png", ".jpg", ".jpeg"] },
        onDrop
    });

    return (
        <div {...getRootProps()} style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            marginRight: "20px",
            border: "dashed white 1px",
            borderRadius: "6px"
        }}>
            <input {...getInputProps()} />
            <Text px="16px">
                <AddIcon mr="8px" /> {uiText.get("cover-edit-dialog.uploadButton")}
            </Text>
        </div>
    );
};

export default UploadCoverImage;
