import React, { FC } from "react";
import { IconButton } from "@chakra-ui/react";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";

export enum ButtonType {
    Up = "up",
    Down = "down"
}

interface IProps {
    type?: ButtonType|string;
    size?: "sm"|"xs";
    onClick?: () => void;
    disabled?: boolean;
}

const ReorderButton: FC<IProps> = (props: IProps) => {
    const handler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (props.onClick) {
            props.onClick();
        }
    };

    const icon = props.type == ButtonType.Up ? <ArrowUpIcon /> : <ArrowDownIcon />;

    return (
        <IconButton
            disabled={props.disabled}
            aria-label={String(props.type)}
            icon={icon}
            size={props.size || "sm"}
            variant="outline"
            onClick={handler} />
    );
};

export default ReorderButton;
