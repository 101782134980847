import { IUser } from "../types";

export function getToken(token: string|IUser): string {
    if (typeof token !== "string") {
        return token.token || "";
    }
    return token;
}

export function getHeaders(token: string|IUser) {
    return {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + getToken(token)
    };
}
