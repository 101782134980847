[
    { "key": "general.not-found.heading", "text": "Oops!" },
    { "key": "general.not-found.text", "text": "This content seems to be missing." },
    { "key": "general.cancel", "text": "Cancel" },

    { "key": "filesize.units", "text": "B,KB,MB,GB,TB,PB,EB,ZB,YB" },

    { "key": "hero.search.placeholder", "text": "Search" },
    { "key": "hero.search.icon.label", "text": "Search" },

    { "key": "menu.title", "text": "TimeStory" },
    { "key": "menu.search.placeholder", "text": "Search Anything" },
    
    { "key": "settings.colorMode", "text": "User-Interface Colour Mode" },
    { "key": "settings.colorMode.light", "text": "Light" },
    { "key": "settings.colorMode.dark", "text": "Dark" },

    { "key": "settings.administrationButton", "text": "Administration" },

    { "key": "admin.closeButton", "text": "Done" },
    { "key": "admin.tabs.general", "text": "General" },
    { "key": "admin.tabs.bookshelves", "text": "Bookshelves" },
    { "key": "admin.tabs.footer", "text": "Footer" },
    
    { "key": "admin.general.hero.title.label", "text": "Hero Element Title" },
    { "key": "admin.general.hero.title.placeholder", "text": "Slogan goes here" },
    { "key": "admin.general.hero.lead.label", "text": "Lead Text" },
    { "key": "admin.general.hero.lead.placeholder", "text": "Description goes here" },
    { "key": "admin.general.hero.lift-book.label", "text": "Highlighted Book" },
    
    { "key": "admin.bookshelves.empty", "text": "This shelf is empty." },
    { "key": "admin.bookshelves.addNew", "text": "Add New" },
    { "key": "admin.bookshelves.edit.title", "text": "Shelf Editor" },
    { "key": "admin.bookshelves.edit.addTitle", "text": "Add New Shelf" },
    { "key": "admin.bookshelves.edit.name.label", "text": "Name" },
    { "key": "admin.bookshelves.edit.public.label", "text": "Visible to the public" },

    { "key": "admin.footer.empty", "text": "(empty)" },
    { "key": "admin.footer.title", "text": "Title" },
    { "key": "admin.footer.url", "text": "Target URL" },
    { "key": "admin.footer.editLinkDialog.title", "text": "Edit Link" },

    { "key": "bookshelf.title", "text": "Bookshelf" },
    { "key": "bookshelf.empty", "text": "Weʼre stacking up the shelves. Stay tuned!" },
    { "key": "bookshelf.allTitles.heading", "text": "All Titles" },
    { "key": "bookshelf.showMore", "text": "Show More" },
    { "key": "bookshelf.edit.label", "text": "Edit" },
    { "key": "bookshelf.new.tooltip", "text": "Drag a Word document here" },
    { "key": "bookshelf.left", "text": "Left" },
    { "key": "bookshelf.right", "text": "Right" },
    { "key": "bookshelf.saveToast.text", "text": "The book has been updated." },
    { "key": "bookshelf.deleteToast.text", "text": "The book has been deleted." },

    { "key": "upload.tooManyFiles.title", "text": "Too many files" },
    { "key": "upload.tooManyFiles.description", "text": "You are only allowed to upload one file at a time." },
    { "key": "upload.title", "text": "File Upload" },
    { "key": "upload.transfer.label", "text": "Transferring {current} of {total}..." },
    { "key": "upload.fileUploaded.title", "text": "Book created" },
    { "key": "upload.fileUploaded.description", "text": "The file has been converted into a TimeStory book. You can modify the parameters." },
    { "key": "upload.conversion.status.Instantiated", "text": "Please wait..." },
    { "key": "upload.conversion.status.SourceReady", "text": "Please wait..." },
    { "key": "upload.conversion.status.ConversionInitializing", "text": "Initializing..." },
    { "key": "upload.conversion.status.ConversionStarted", "text": "Converting material..." },
    { "key": "upload.conversion.status.NamePicked", "text": "Processing cover image..." },
    { "key": "upload.conversion.status.CoverImagePicked", "text": "Processing cover image..." },
    { "key": "upload.conversion.status.ConversionToIntermediateFormatStarting", "text": "Processing..." },
    { "key": "upload.conversion.status.ConversionToIntermediateFormatStarted", "text": "Processing..." },
    { "key": "upload.conversion.status.ConversionToIntermediateFormatCreated", "text": "Processing..." },
    { "key": "upload.conversion.status.ConversionPublishingStarted", "text": "Publishing..." },
    { "key": "upload.conversion.status.ConversionEnded", "text": "Conversion cancelled (reason unknown)" },
    { "key": "upload.conversion.status.ConversionEndedInTimeout", "text": "Conversion ended in timeout." },
    { "key": "upload.conversion.status.ConversionEndedInError", "text": "Conversion ended in error." },
    { "key": "upload.conversion.status.ConversionEndedSuccesfully", "text": "Success!" },

    { "key": "search.query.placeholder", "text": "Search" },
    { "key": "search.results.heading", "text": "Search Results for “{query}”" },
    { "key": "search.no-results.heading", "text": "No matches found for “{query}”" },

    { "key": "signIn.heading", "text": "Are you an administrator?" },
    { "key": "signIn.button", "text": "Sign in Here" },
    { "key": "signIn.signedInAs", "text": "You are signed in as {user}." },
    { "key": "signIn.signOut", "text": "Sign Out" },
    { "key": "signIn.form.username.label", "text": "Username" },
    { "key": "signIn.form.username.placeholder", "text": "first.last@example.com" },
    { "key": "signIn.form.password.label", "text": "Password" },
    { "key": "signIn.form.password.placeholder", "text": "No peeking!" },
    { "key": "signIn.form.submit", "text": "Sign In" },
    { "key": "signIn.form.cancel", "text": "Cancel" },
    { "key": "signIn.error.INVALID_CREDENTIALS", "text": "Invalid username or password." },
    { "key": "signIn.passwordReset.link", "text": "Forgot your password?" },
    { "key": "signIn.passwordReset.email.label", "text": "Email" },
    { "key": "signIn.passwordReset.email.placeholder", "text": "first.last@example.com" },
    { "key": "signIn.passwordReset.email.helperText", "text": "You'll receive instructions to your inbox." },
    { "key": "signIn.passwordReset.submit", "text": "Send" },
    { "key": "signIn.passwordReset.cancel", "text": "Cancel" },

    { "key": "footer.email.placeholder", "text": "first.last@example.com" },
    { "key": "footer.email.helpText", "text": "We will never share your email with anyone." },

    { "key": "pub-edit-dialog.title", "text": "Edit Book" },
    { "key": "pub-edit-dialog.save", "text": "Save" },
    { "key": "pub-edit-dialog.cancel", "text": "Cancel" },
    { "key": "pub-edit-dialog.delete", "text": "Delete" },
    { "key": "pub-edit-dialog.delete-confirm.title", "text": "Confirm Delete" },
    { "key": "pub-edit-dialog.delete-confirm.body", "text": "Are you sure? You can't undo this action afterwards." },
    { "key": "pub-edit-dialog.delete-confirm.cancel", "text": "Cancel" },
    { "key": "pub-edit-dialog.delete-confirm.confirm", "text": "Delete" },
    { "key": "pub-edit-dialog.more-settings", "text": "More Settings" },
    { "key": "pub-edit-dialog.less-settings", "text": "Less Settings" },
    { "key": "pub-edit-dialog.pub.title.label", "text": "Title" },
    { "key": "pub-edit-dialog.pub.title.placeholder", "text": "The Great Gatsby" },
    { "key": "pub-edit-dialog.pub.description.label", "text": "Description" },
    { "key": "pub-edit-dialog.pub.description.placeholder", "text": "Back cover copy" },
    { "key": "pub-edit-dialog.pub.description.charsUsed", "text": "{used}/{max} characters used" },
    { "key": "pub-edit-dialog.pub.public.label", "text": "This book is visible to the public" },
    { "key": "pub-edit-dialog.pub.cover.label", "text": "Cover" },
    { "key": "pub-edit-dialog.pub.coverEditButton", "text": "Edit" },
    { "key": "pub-edit-dialog.pub.coverResetButton", "text": "Reset" },
    { "key": "pub-edit-dialog.pub.newVersionButton", "text": "New Version" },
    { "key": "pub-edit-dialog.pub.newVersionUploaded.title", "text": "Upload completed" },
    { "key": "pub-edit-dialog.pub.newVersionUploaded.content", "text": "The file has been queued for processing." },
    { "key": "pub-edit-dialog.pub.newVersionReady.title", "text": "Ready" },
    { "key": "pub-edit-dialog.pub.newVersionReady.content", "text": "A new document version has been updated." },

    { "key": "cover-edit-dialog.title", "text": "Crop Image" },
    { "key": "cover-edit-dialog.uploadButton", "text": "Upload New" },
    { "key": "cover-edit-dialog.doneButton", "text": "Done" },
    { "key": "cover-edit-dialog.cancelButton", "text": "Cancel" }
]
