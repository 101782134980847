/* eslint-disable no-magic-numbers */
import { extendTheme } from "@chakra-ui/react";
import colors from "./colors";

const theme = extendTheme({
    config: {
        useSystemColorMode: true
    },
    colors,
    fonts: {
        heading: "'Readex Pro', sans-serif",
        body: "'Open Sans', sans-serif",
    },
    components: {
        Container: {
            baseStyle: {
                maxWidth: "1200px"
            }
        }
    },
});

export default theme;
