import React, { FC } from "react";
import { State, useHookstate } from "@hookstate/core";
import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Text,
    useColorMode
} from "@chakra-ui/react";
import { UIText } from "../../UIText";
import { uploadState } from "../../state";
import colors from "../../theme/colors";
import { humanFileSize } from "../../utils";
import useLocalization from "../../hooks/useLocalization";
import { INewBook } from "../../types";

const PROGRESS_ANIM_ELEMENTS = 4;

const UploadProgress: FC = () => {
    const { colorMode } = useColorMode();
    const { uiText } = useLocalization();

    const upload = useHookstate(uploadState);

    const file = upload.file.get();

    if (!file) {
        return null;
    }

    return (
        <Modal
            isOpen={shouldBeOpen(upload.status.get())}
            onClose={() => upload.status.set("NotInProgress")}
            size="xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader bgColor="teal.700" color="white">
                    {uiText.get("upload.title")}
                </ModalHeader>
                <ModalBody>
                    <Flex alignItems="center" justifyContent="center">
                        <Box pb="5px">
                            <Text
                                color={colorMode == "light" ? "teal.700" : "teal.500"}
                                fontWeight={600}
                            >
                                {getUploadStatusText(upload, uiText)}
                            </Text>
                            <Text fontSize="xs">
                                {upload.name.get() || file.name}
                            </Text>
                        </Box>
                        <Spacer />
                        <Box>
                            <div className={"lds-ellipsis"}>
                                {[...Array(PROGRESS_ANIM_ELEMENTS).keys()].map(x => (
                                    <div
                                        style={{
                                            backgroundColor: colorMode == "dark" ?
                                                colors.teal["500"] : colors.teal["700"]
                                        }}
                                        key={x}></div>
                                ))}
                            </div>
                        </Box>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            upload.file.set(undefined);
                        }}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

function getUploadStatusText(state: State<INewBook>, uiText: UIText): string {
    switch (state.status.get()) {
    case "Uploading":
        return uiText.get("upload.transfer.label", {
            current: humanFileSize(
                state.transferred.get() || 0,
                1,
                uiText.get("filesize.units").split(",")
            ),
            total: humanFileSize(
                state.totalSize.get() || 0,
                1,
                uiText.get("filesize.units").split(",")
            )
        });
    }

    return uiText.get("upload.conversion.status." + state.status.get());
}

function shouldBeOpen(status: string): boolean {
    return status !== "ConversionEndedSuccesfully";
}

export default UploadProgress;
