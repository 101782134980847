import React, { FC } from "react";
import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import useLocalization from "../../hooks/useLocalization";
import { State } from "@hookstate/core";

interface IProps {
    state: State<string>;
    disabled: boolean;
    error?: string;
}

export const PasswordInput: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();

    return (
        <FormControl mt="20px" isInvalid={Boolean(props.error)}>
            <FormLabel htmlFor="password">
                {uiText.get("signIn.form.password.label")}
            </FormLabel>

            <Input
                disabled={props.disabled}
                id="password"
                type="password"
                placeholder={uiText.get("signIn.form.password.placeholder")}
                value={props.state.get()}
                onChange={e => props.state.set(e.target.value)} />

            <FormErrorMessage>
                {uiText.get("signIn.error." + props.error)}
            </FormErrorMessage>
        </FormControl>
    );
};
