import { useHookstate } from "@hookstate/core";
import { currentUserState } from "../state";
import { IUser } from "../types";

export interface IUserHook {
    get: () => IUser;
    isSignedIn: () => boolean;
    update: (fields: IUser) => void;
    clear: () => void;
}

export default function (): IUserHook {
    const currentUser = useHookstate(currentUserState);

    return {
        get: () => currentUser.get(),
        isSignedIn: () => Boolean(currentUser.token.get()),
        update: fields => currentUser.set(prev => ({ ...prev, ...fields })),
        clear: () => {
            currentUser.token.set(undefined);
            currentUser.isAdmin.set(undefined);
        }
    };
}
