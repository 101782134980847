import axios, { Method } from "axios";
import { IUser } from "../types";
import { getHeaders } from "./utils";

export abstract class APIBase {
    constructor(protected readonly user: IUser) {}

    protected get(url: string) {
        return this.request("get", url);
    }

    protected post(url: string, data: any) {
        return this.request("post", url, data);
    }

    protected put(url: string, data: any = undefined) {
        return this.request("put", url, data);
    }

    protected delete(url: string) {
        return this.request("delete", url);
    }

    protected request(method: Method, url: string, data: any = undefined) {
        return axios({
            method,
            url,
            data,
            headers: getHeaders(this.user)
        });
    }
}
