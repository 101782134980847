import React, { FC, useEffect } from "react";
import { Box, Heading, Spinner } from "@chakra-ui/react";
import useLocalization from "../../hooks/useLocalization";
import { SearchResult } from "./SearchResult";
import useApi from "../../hooks/useApi";
import { useHookstate } from "@hookstate/core";
import { bookshelfState } from "../../state";
import { ISearchResults } from "../../types";

interface IProps {
    query: string;
}

export const SearchResults: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const { query } = props;

    const api = useApi();
    const shelfState = useHookstate(bookshelfState);
    const state = useHookstate<ISearchResults>({ resultBooks: [], totalBookCount: 0 });
    const waiting = useHookstate(true);

    useEffect(() => {
        waiting.set(true);

        api.search().search(shelfState.shelves.get(), props.query).then(results => {
            state.set(results);
            waiting.set(false);
        });

    }, [props.query]);

    const headingKey = state.resultBooks.length == 0 ?
        "search.no-results.heading" : "search.results.heading";

    if (waiting.get()) {
        return (
            <Box pb="80px" textAlign="center">
                <Spinner size="xl" />
            </Box>
        );
    }

    return (
        <Box pb="80px">
            <Heading as="h1">
                {uiText.get(headingKey, { query })}
            </Heading>

            {state.resultBooks.get().map((result, index) => (
                <SearchResult
                    key={index}
                    data={result} />
            ))}
        </Box>
    );
};
