import React, { FC, RefObject } from "react";
import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Spacer,
} from "@chakra-ui/react";
import useLocalization from "../../hooks/useLocalization";
import { IParent } from "../../types";

interface IProps {
    onClose: () => void;
    initialFocusRef?: RefObject<HTMLInputElement>;
}

type Props = IParent<React.ReactNode> & IProps;

const DialogWrapper: FC<Props> = (props: Props) => {
    const { uiText } = useLocalization();

    return (
        <Modal
            isOpen={true}
            onClose={props.onClose}
            size="5xl"
            initialFocusRef={props.initialFocusRef}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />

                <ModalBody>
                    {props.children}
                </ModalBody>

                <ModalFooter>
                    <Flex w="100%">
                        <Spacer />
                        <Box>
                            <Button onClick={props.onClose}>
                                {uiText.get("admin.closeButton")}
                            </Button>
                        </Box>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DialogWrapper;
