import React, { FC } from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { LayoutSection } from "../layouts/LayoutSection";
import useLocalization from "../hooks/useLocalization";

const NotFound: FC = () => {
    const { uiText } = useLocalization();

    return (
        <LayoutSection>
            <Box py="80px">
                <Heading as="h2">
                    {uiText.get("general.not-found.heading")}
                </Heading>

                <Text mt="20px">
                    {uiText.get("general.not-found.text")}
                </Text>
            </Box>
        </LayoutSection>
    );
};

export default NotFound;

