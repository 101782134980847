import React, { FC } from "react";
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    IconButton,
    Spacer,
    useColorMode,
    useDisclosure
} from "@chakra-ui/react";
import { ColorModeSelector } from "./ColorModeSelector";
import { ProductLogo } from "./ProductLogo";
import useLocalization from "../../hooks/useLocalization";
import SignIn from "../SignIn";
import Search from "./Search";
import useUser from "../../hooks/useUser";
import AdministrationDialog from "../AdministrationDialog";

interface IProps {
    withLogo?: boolean;
}

export const HamburgerMenu: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const user = useUser();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.createRef<HTMLInputElement>();

    const admin = useDisclosure();

    return (
        <>
            <Flex>
                {props.withLogo ? <ProductLogo /> : null}

                <Box ml="12px">
                    <IconButton
                        onClick={onOpen}
                        variant="ghost"
                        icon={<Icon />}
                        aria-label="Menu" />
                </Box>
            </Flex>

            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        {uiText.get("menu.title")}
                    </DrawerHeader>

                    <DrawerBody>
                        <Flex direction="column" height="100%">
                            <Box>
                                <Search
                                    onSearch={() => {
                                        onClose();
                                    }} />
                            </Box>

                            <Box mt="40px">
                                <ColorModeSelector />
                            </Box>

                            <Spacer />

                            {user.isSignedIn() ? (<Box mb="40px">
                                <>
                                    {admin.isOpen ? (
                                        <AdministrationDialog
                                            onClose={admin.onClose} />
                                    ) : null}
                                    <Button
                                        onClick={() => {
                                            admin.onOpen();
                                        }}
                                        w="100%">
                                        {uiText.get("settings.administrationButton")}
                                    </Button>
                                </>
                            </Box>) : null}

                            <Box mb="20px">
                                <SignIn />
                            </Box>
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export const Icon: FC = () => {
    const { colorMode } = useColorMode();

    const color = colorMode == "dark" ?
        "rgba(255, 255, 255, 0.6)" :
        "rgba(  0,   0,   0, 0.25)";

    return (
        <svg viewBox="0 0 100 100" width="40" height="40">
            <rect x="20" y="20" width="60" height="6" fill={color}></rect>
            <rect x="20" y="47" width="60" height="6" fill={color}></rect>
            <rect x="20" y="74" width="60" height="6" fill={color}></rect>
        </svg>
    );
};
