import React, { FC, useEffect } from "react";
import {
    HashRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "../theme";
import { MainLayout } from "../layouts/MainLayout";
import { LayoutSection } from "../layouts/LayoutSection";
import Header from "./Header";
import Hero from "./Hero";
import Footer from "./Footer";
import NotFound from "./NotFound";
import Search from "./Search";
import useLocalization from "../hooks/useLocalization";
import BookshelfList from "./BookshelfList";
import { MetaAPI } from "../apiProxy/meta";
import { metaState } from "../state";
import { useState } from "@hookstate/core";
import { setupBrowserConsoleTools } from "../browser-console";
import useUser from "../hooks/useUser";

export const Application: FC = () => {
    const meta = useState(metaState);
    const localization = useLocalization();
    const user = useUser();
    const api = new MetaAPI(user.get());

    useEffect(() => {
        localization.reload();

        api.getMeta().then(data => {
            meta.collection.set(data);
            meta.initializing.set(false);
        });

        setupBrowserConsoleTools();
    }, []);

    if (!localization.ready) {
        return null;
    }

    return (
        <ChakraProvider theme={theme}>
            <MainLayout>
                <Router>
                    <Header />

                    <Routes>
                        <Route path="/search" element={<Search />} />

                        <Route index element={<>
                            <LayoutSection fullWidth>
                                <Hero />
                            </LayoutSection>

                            <LayoutSection>
                                <BookshelfList />
                            </LayoutSection>
                        </>} />

                        <Route path="*" element={<NotFound />} />
                    </Routes>

                    <LayoutSection fullWidth inverted>
                        <Footer />
                    </LayoutSection>
                </Router>
            </MainLayout>
        </ChakraProvider>
    );
};
