type StringResolver = () => string;

type UITextParamValue = string|number|StringResolver;

type UITextParams = {
    [key: string]: UITextParamValue
};

export type UIDictionary = { [key: string]: string };

export class UIText {
    private dictionary: UIDictionary;

    constructor(dictionary: UIDictionary) {
        this.dictionary = dictionary;
    }

    get(key: string, params: UITextParams = {}): string {
        const format = this.dictionary[key] || key;
        const keys = Object.keys(params);

        let output = format;
        for (const key of keys) {
            let p = params[key];
            if (typeof p == "function") {
                p = p();
            }
            output = output.replace(`{${key}}`, String(p));
        }

        return output;
    }
}
