import React, { FC } from "react";
import { IParent } from "../types";
import useUser from "../hooks/useUser";

type Props = IParent<React.ReactNode>;

const ForAdmins: FC<Props> = (props: Props) => {
    const user = useUser();

    if (user.isSignedIn() && user.get().isAdmin) {
        return (
            <>
                {props.children}
            </>
        );
    }

    return null;
};

export default ForAdmins;
