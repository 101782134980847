import React, { FC, useEffect } from "react";
import {
    Box,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    useColorMode
} from "@chakra-ui/react";
import searchIconUrlWhite from "../../../assets/search-icon.svg";
import searchIconUrlBlack from "../../../assets/search-icon-black.svg";
import useLocalization from "../../hooks/useLocalization";
import { useHookstate } from "@hookstate/core";
import { searchState } from "../../state";

interface IProps {
    initialQuery?: string;
    onReset: () => void;
    onSearch: (query: string) => void;
    disabled?: boolean;
}

export const SearchBox: FC<IProps> = (props: IProps) => {
    const { colorMode } = useColorMode();
    const { uiText } = useLocalization();
    const query = useHookstate(searchState);
    const userInput = useHookstate(query.get() || "");

    const iconUrl = colorMode == "dark" ? searchIconUrlWhite : searchIconUrlBlack;

    useEffect(() => {
        userInput.set(query.get());
    }, [query.get()]);

    const fireSearchEvent = () => {
        const query = userInput.get().trim();
        if (query.length == 0) {
            props.onReset();
        }
        else {
            props.onSearch(query);
        }
    };

    return (
        <Box>
            <InputGroup size="lg">
                <Input
                    disabled={props.disabled}
                    onKeyPress={e => {
                        if (e.key == "Enter") {
                            fireSearchEvent();
                        }
                    }}
                    onChange={e => userInput.set(e.target.value)}
                    value={userInput.get()}
                    w="100%"
                    variant="filled"
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="blue.800"
                    placeholder={uiText.get("search.query.placeholder")} />

                <InputRightElement>
                    <IconButton
                        disabled={props.disabled}
                        onClick={() => fireSearchEvent()}
                        backgroundColor="transparent"
                        size="sm"
                        icon={<Image src={iconUrl} />}
                        aria-label="Search" />
                </InputRightElement>
            </InputGroup>
        </Box>
    );
};
