import { Box, Heading, Spinner } from "@chakra-ui/react";
import { useState } from "@hookstate/core";
import React, { FC, useEffect } from "react";
import useApi from "../hooks/useApi";
import useLocalization from "../hooks/useLocalization";
import { bookshelfState } from "../state";
import Bookshelf from "./Bookshelf";

const BookshelfList: FC = () => {
    const { uiText } = useLocalization();
    const api = useApi();

    const state = useState(bookshelfState);
    const fetching = useState(false);

    useEffect(() => {
        fetching.set(true);
        api.shelf().getShelves().then(shelves => {
            state.shelves.set(shelves);
            fetching.set(false);
        }).catch(() => {
            fetching.set(false);
        });
    }, []);

    if (state.shelves.length == 0) {
        if (fetching.get()) {
            return (
                <Box textAlign="center" py="60px">
                    <Spinner size="xl" />
                </Box>
            );
        }

        return (
            <Box my="80px">
                <Heading textAlign="center">
                    {uiText.get("bookshelf.empty")}
                </Heading>
            </Box>
        );
    }

    return (
        <Box py="60px">
            {state.shelves.map(shelf => (
                <Bookshelf
                    state={shelf}
                    key={shelf.id.get()} />
            ))}
        </Box>
    );
};

export default BookshelfList;
