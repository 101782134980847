import { Box, Button, Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import React, { FC } from "react";
import useLocalization from "../../hooks/useLocalization";
import { IBook } from "../../types";
import Confirm from "../Confirm";
import UploadNewVersion from "./UploadNewVersion";

interface IProps {
    book: IBook;
    onCancel: () => void;
    onSave: () => void;
    onDelete: () => void;
}

const DialogFooter: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const deleteConfirm = useDisclosure();

    return (
        <>
            <Flex w="100%">
                <UploadNewVersion book={props.book} />

                <Button
                    variant="outline"
                    colorScheme="red"
                    onClick={deleteConfirm.onOpen}
                >
                    {uiText.get("pub-edit-dialog.delete")}
                </Button>

                <Spacer />

                <Box>
                    <Button mr={3} onClick={props.onSave}>
                        {uiText.get("pub-edit-dialog.save")}
                    </Button>
                    <Button
                        variant="ghost"
                        onClick={props.onCancel}>
                        {uiText.get("pub-edit-dialog.cancel")}
                    </Button>
                </Box>
            </Flex>

            <Confirm
                title={uiText.get("pub-edit-dialog.delete-confirm.title")}
                content={uiText.get("pub-edit-dialog.delete-confirm.body")}
                isOpen={deleteConfirm.isOpen}
                onClose={deleteConfirm.onClose}
                confirmButtonText={uiText.get("pub-edit-dialog.delete-confirm.confirm")}
                onConfirm={() => {
                    deleteConfirm.onClose();
                    props.onDelete();
                }} />
        </>
    );
};

export default DialogFooter;
