import React, { FC } from "react";
import { Box, IconButton, useColorModeValue } from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import useLocalization from "../../hooks/useLocalization";

interface IProps {
    side: "left" | "right";
    disabled?: boolean;
    onClick: () => void;
}

const ScrollButton: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();

    const bgColor = useColorModeValue(
        "rgba(0, 0, 0, 0.25)",
        "rgba(0, 0, 0, 0.5)"
    );

    return (
        <Box
            height="100px"
            bottom="125px"
            position="absolute"
            display={["none", "block"]}
            left={props.side == "left" ? "-16px" : undefined}
            right={props.side == "left" ? undefined : "-16px"}
        >
            <IconButton
                bgColor={bgColor}
                borderRadius="none"
                onClick={props.onClick}
                disabled={props.disabled}
                height="100%"
                display="block"
                size="lg"
                aria-label={uiText.get("bookshelf." + props.side)}
                icon={props.side == "left" ? <ArrowBackIcon /> : <ArrowForwardIcon />} />
        </Box>
    );
};

export default ScrollButton;
