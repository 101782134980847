import { IUser } from "../types";
import axios from "axios";
import configData from "../../config";

export interface ISignInResult {
    user?: IUser;
    error?: string;
    token?: string;
}

export function signIn(username: string, password: string): Promise<ISignInResult> {
    return new Promise(resolve => {
        localStorage.removeItem("token");

        const loginJson = JSON.stringify({
            username: username,
            password: password });

        axios({
            method: "post",
            url: configData.timeStoryAPIRootUrl + configData.authentication.loginUrl,
            data: loginJson,
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.data && response.data.token)
                {
                    localStorage.setItem("token", response.data.token);

                    resolve({
                        user: {
                            locale: "en",
                            // JWT:n tallennus clientille
                            token: response.data.token,
                            email: username,
                            isAdmin: true
                        }
                    });
                    return;
                }

                // eslint-disable-next-line no-console
                console.log("Error on login: Access token not found.");
                resolve({ error: "INVALID_CREDENTIALS" });
                return;

            })
            .catch(() => {
                // eslint-disable-next-line no-console
                console.log("Error on login: Not authorized or some other error.");
                resolve({ error: "INVALID_CREDENTIALS" });
                return;
            });
    });
}
