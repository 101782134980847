import React, { FC, useEffect } from "react";
import { Box, Container, Flex, Heading, Skeleton, SkeletonText, Text } from "@chakra-ui/react";
import { IBook } from "../../types";
import Cover from "./Cover";
import { useState } from "@hookstate/core";
import { metaState } from "../../state";
import useApi from "../../hooks/useApi";

const Hero: FC = () => {
    const { initializing: skeletonMode, collection: meta } = useState(metaState);
    const api = useApi();

    let lift = useState<IBook|undefined>(undefined);

    useEffect(() => {
        const bookId = Number(meta["hero.lift-book-id"].get() || "0");
        if (!bookId) return;

        api.book().getBook(bookId).then(book => {
            lift.set(book);
        }).catch(() => {
            lift.set(undefined);
        });
    }, [meta["hero.lift-book-id"].get()]);

    return (
        <Box
            backgroundColor="maguerite.500"
            color="white"
            backgroundImage={meta["hero.backgroundImage"].get()}
        >
            <Container p={0}>
                <Flex minH="45vh" alignItems="center" flexWrap="wrap">
                    <Box flex="1 1 400px" py="40px" px="16px">
                        <Heading as="h1" fontSize="6xl" mb="30px">
                            {skeletonMode.get() ?
                                <Skeleton height="60px" />
                                : meta["hero.title"].get()}
                        </Heading>

                        <Text as="div" opacity={.8}>
                            {skeletonMode.get() ?
                                <SkeletonText noOfLines={3} spacing={5} />
                                : meta["hero.lead"].get()}
                        </Text>
                    </Box>

                    <Box flex="1 1 400px" alignSelf="flex-end">
                        {<Cover book={lift.get()} />}
                    </Box>
                </Flex>
            </Container>
        </Box>
    );
};

export default Hero;
