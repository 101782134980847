import { IBookshelf, IModifyBookshelf } from "../types";
import configData from "../../config";
import { APIBase } from "./APIBase";
import { toQueryString } from "../utils";

interface IPutBookParams {
    shelfId: number;
    bookId: number;
    siblingBookId: number;
    before: boolean;
}

export class ShelfAPI extends APIBase {
    getShelves(bookCount = 100, skip = 0, take = 100): Promise<IBookshelf[]> {
        let url = configData.timeStoryAPIRootUrl + configData.shelf.baseUrl;
        url += `?Skip=${skip}&Take=${take}&SelectBookCount=${bookCount}`;

        return new Promise(resolve => {
            this.get(url)
                .then((response) => resolve(response.data.shelves))
                .catch(e => { throw e; });
        });
    }

    addShelf(name: string, isPublic: boolean): Promise<IBookshelf> {
        const url = configData.timeStoryAPIRootUrl + configData.shelf.baseUrl;

        return new Promise((resolve, reject) => {
            this.post(url, {
                name,
                isPublic,
                meta: [],
                position: null
            })
                .then((response) => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    modifyShelf(id: number, fields: IModifyBookshelf) {
        const url =
            configData.timeStoryAPIRootUrl +
            configData.shelf.baseUrl +
            `/${id}`;

        return new Promise((resolve, reject) => {
            this.put(url, fields)
                .then((response) => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    deleteShelf(id: number) {
        const url =
            configData.timeStoryAPIRootUrl +
            configData.shelf.baseUrl +
            `/${id}`;

        return new Promise((resolve, reject) => {
            this.delete(url)
                .then((response) => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    putBook({ shelfId, bookId, siblingBookId, before }: IPutBookParams) {
        const url =
            configData.timeStoryAPIRootUrl + configData.shelf.baseUrl +
            `/${shelfId}${configData.shelf.book}/${bookId}?` +
            toQueryString({
                SiblingBookId: siblingBookId,
                Before: String(before)
            });

        return new Promise((resolve, reject) => {
            this.put(url)
                .then((response) => resolve(response.data))
                .catch(error => reject(error));
        });
    }
}
