import config from "../config";
import { IMAGE_BOX_NAME } from "./constants";
import { IBook } from "./types";

export function getCoverImageUrl(book: IBook|null|undefined, original = false): string {
    let url = book?.coverImageURL || "";

    if (url) {
        url = getCompleteCoverImageUrl(url, original);
    }

    return url;
}

export function getCompleteCoverImageUrl(relativeUrl: string, original = false): string {
    let url = config.coverImageUrlPrefix + relativeUrl;

    if (!original) {
        url += "&BoxName=" + encodeURIComponent(IMAGE_BOX_NAME);
    }

    return url;
}
