import axios from "axios";
import { IMaterial, ProgressCallback } from "../types";
import configData from "../../config";
import { getHeaders } from "./utils";
import { APIBase } from "./APIBase";

export class MaterialAPI extends APIBase {
    getMaterial(materialId: number): Promise<IMaterial> {
        let url = configData.timeStoryAPIRootUrl + configData.materials.baseUrl;
        url += `/${materialId}`;

        return new Promise((resolve, reject) => {
            this.get(url)
                .then((response) => resolve(response.data))
                .catch(e => reject(e));
        });
    }

    uploadMaterial(file: any, progress: ProgressCallback): Promise<IMaterial>
    {
        let data = new FormData();
        data.append("file", file);

        let url = configData.timeStoryAPIRootUrl + configData.materials.baseUrl;

        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url,
                data,
                headers: getHeaders(this.user),
                onUploadProgress: e => progress(e.loaded, e.total)
            })
                .then((response) => resolve(response.data))
                .catch(e => reject(e));
        });
    }
}
