import React, { createRef, FC } from "react";
import { Box } from "@chakra-ui/react";
import { IBookshelf } from "../../types";
import { State } from "@hookstate/core";
import ScrollButton from "./ScrollButton";
import BookList from "./BookList";

const RELATIVE_SCROLL_STEP = 0.6; // 1 == screen width

enum ScrollDirection {
    left = -1,
    right = +1
}

interface IProps {
    shelf: State<IBookshelf>;
}

const BookContainer: FC<IProps> = (props: IProps) => {
    const containerRef = createRef<HTMLDivElement>();

    return (
        <>
            <BookList
                scrollRef={containerRef}
                shelf={props.shelf} />

            <Box position="relative">
                <ScrollButton
                    onClick={() => {
                        smoothScroll(containerRef.current, ScrollDirection.left);
                    }}
                    side="left" />

                <ScrollButton
                    onClick={() => {
                        smoothScroll(containerRef.current, ScrollDirection.right);
                    }}
                    side="right" />
            </Box>
        </>
    );
};

function smoothScroll(elem: HTMLDivElement|null, dir: ScrollDirection) {
    if (elem == null) return;
    const target = elem.scrollLeft + getScrollStep(elem) * dir;
    elem.scrollTo({ left: target, behavior: "smooth" });
}

function getScrollStep(elem: HTMLDivElement) {
    return Math.round(elem.clientWidth / RELATIVE_SCROLL_STEP);
}

export default BookContainer;
