import React from "react";
import { render } from "react-dom";
import { Application } from "./components/Application";

function Root() {
    return (
        <Application />
    );
}

render(<Root />, document.getElementById("root"));
