import React, { FC } from "react";
import { Box, Button } from "@chakra-ui/react";
import useLocalization from "../../hooks/useLocalization";

interface IProps {
    onClick: () => void;
}

export const PasswordResetLink: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();

    return (
        <Box mt="20px">
            <Button variant="link" onClick={props.onClick}>
                {uiText.get("signIn.passwordReset.link")}
            </Button>
        </Box>
    );
};
