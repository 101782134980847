import { createState } from "@hookstate/core";
import { LOCALE_PRECEDENCE } from "./constants";
import {
    IEditBook,
    INewBook,
    IUser,
    IBookshelfState,
    IMetaState
} from "./types";
import { UIDictionary } from "./UIText";
import { getSessionLocale } from "./utils";

export const uiDictionaryState = createState<UIDictionary>({});

export const currentUserState = createState<IUser>({
    locale: getSessionLocale() || LOCALE_PRECEDENCE[0]
});

export const editState = createState<IEditBook>({});

export const uploadState = createState<INewBook>({
    status: "NotInProgress",
    name: null
});

export const searchState = createState("");

export const bookshelfState = createState<IBookshelfState>({
    shelves: []
});

export const metaState = createState<IMetaState>({
    initializing: true,
    collection: {}
});
