import React, { FC, useEffect } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import { useHookstate, useState } from "@hookstate/core";
import { IFooterLink, InstanceMetaKey } from "../../../types";
import useLocalization from "../../../hooks/useLocalization";
import useUser from "../../../hooks/useUser";
import { metaState } from "../../../state";
import { MetaAPI } from "../../../apiProxy/meta";

interface IProps {
    metaKey?: InstanceMetaKey;
    onDone: () => void;
}

const DEFAULT_MODEL: IFooterLink = {
    title: "",
    url: ""
};

const EditLinkDialog: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const user = useUser();
    const meta = useState(metaState);

    const model = useHookstate({ ...DEFAULT_MODEL });

    const api = new MetaAPI(user.get());

    useEffect(() => {
        if (props.metaKey) {
            let link: IFooterLink = { ...DEFAULT_MODEL };

            const json = meta.collection[props.metaKey].get();
            if (json) {
                link = JSON.parse(json);
            }

            model.set(link);
        }
        else {
            model.set(DEFAULT_MODEL);
        }
    }, [props.metaKey]);

    return (
        <Modal isOpen={Boolean(props.metaKey)} onClose={props.onDone}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{uiText.get("admin.footer.editLinkDialog.title")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <FormLabel htmlFor="title">
                            {uiText.get("admin.footer.title")}
                        </FormLabel>

                        <Input
                            id="title"
                            type="text"
                            placeholder={"Text to display"}
                            value={model.title.get()}
                            onChange={e => model.title.set(e.target.value)} />
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel htmlFor="url">
                            {uiText.get("admin.footer.url")}
                        </FormLabel>

                        <Input
                            id="url"
                            type="text"
                            placeholder={"https://your-server/your-page"}
                            value={model.url.get()}
                            onChange={e => model.url.set(e.target.value)} />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Box>
                        <Button
                            mr={3}
                            onClick={() => {
                                if (!props.metaKey) {
                                    return;
                                }

                                api.setMeta(
                                    props.metaKey,
                                    JSON.stringify(model.get())
                                ).then(() => {
                                    meta.collection[props.metaKey as string]
                                        .set(JSON.stringify(model.get()));
                                    props.onDone();
                                });
                            }}
                        >
                            Save
                        </Button>
                        <Button variant="ghost" onClick={props.onDone}>
                            Cancel
                        </Button>
                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default EditLinkDialog;
