import React, { FC } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { IBook } from "../../../types";
import ReorderButton from "./ReorderButton";
import { NOT_PUBLIC_OPACITY } from "../../../constants";

interface IProps {
    book: IBook;
    allowUp: boolean;
    allowDown: boolean;
    onMoveUp: () => void;
    onMoveDown: () => void;
}

const BookElement: FC<IProps> = (props: IProps) => {
    const { book } = props;

    return (
        <Flex pr="12px" gap="8px">
            <ReorderButton
                type="up"
                size="xs"
                disabled={!props.allowUp}
                onClick={() => props.onMoveUp()} />
            <ReorderButton
                type="down"
                size="xs"
                disabled={!props.allowDown}
                onClick={() => props.onMoveDown()} />
            <Box
                ml={2}
                fontSize="sm"
            >
                <Text as="span" opacity={book.isPublic ? 1 : NOT_PUBLIC_OPACITY}>
                    {book.name}
                </Text>
            </Box>
        </Flex>
    );
};

export default BookElement;
