import React, { FC } from "react";
import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";
import { COVER_ASPECT_RATIO } from "../../constants";
import { Cover } from "./Cover";
import { ISearchResult } from "../../types";
import { BookPropReader } from "../BookPropReader";

const COVER_HEIGHT_DESKTOP = 120;
const COVER_WIDTH_DESKTOP = COVER_HEIGHT_DESKTOP * COVER_ASPECT_RATIO;

const COVER_HEIGHT_MOBILE = 70;
const COVER_WIDTH_MOBILE = COVER_HEIGHT_MOBILE * COVER_ASPECT_RATIO;

interface IProps {
    data: ISearchResult;
}

export const SearchResult: FC<IProps> = (props: IProps) => {
    const book = new BookPropReader(props.data.book);

    return (
        <Flex gap="40px" mt="60px">
            <Box display={["none", "block"]}>
                <Cover
                    url={book.getCoverImageUrl()}
                    w={COVER_WIDTH_DESKTOP}
                    h={COVER_HEIGHT_DESKTOP} />
            </Box>

            <Box w="100%">
                <Box float="right" display={["block", "none"]}>
                    <Cover
                        url={book.getCoverImageUrl()}
                        w={COVER_WIDTH_MOBILE}
                        h={COVER_HEIGHT_MOBILE} />
                </Box>

                <Heading
                    as="h2"
                    size="md"
                    cursor="pointer"
                    _hover={{
                        textDecoration: "underline"
                    }}
                    onClick={() => {
                        window.open(book.getReadUrl(), "_blank");
                    }}
                >
                    {book.getName()}
                </Heading>

                {props.data.positions.map((hit, index) => (
                    <Box mt="20px" key={index}>
                        <Text
                            display="none"
                            fontSize="xs"
                            opacity={.6}
                            cursor="pointer"
                            _hover={{
                                textDecoration: "underline"
                            }}
                            onClick={() => {
                                window.open(book.getReadUrl(), "_blank");
                            }}
                        >
                            Chapter
                        </Text>

                        <Link isExternal
                            fontSize="md"
                            cursor="pointer"
                            _hover={{
                                textDecoration: "underline"
                            }}
                            href={book.getReadUrl() +
                                hit.positionPath[hit.positionPath.length - 1].hash}
                        >
                            <div
                                className="search-result"
                                dangerouslySetInnerHTML={{ __html: hit.sampleHTML }}
                            />
                        </Link>
                    </Box>
                ))}
            </Box>
        </Flex>
    );
};
