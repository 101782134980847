import React, { FC } from "react";
import { Button, useDisclosure } from "@chakra-ui/react";
import Confirm from "../../Confirm";

interface IProps {
    onDelete: () => void;
}

const DeleteWithConfirm: FC<IProps> = (props: IProps) => {
    const dialog = useDisclosure();

    return (
        <>
            <Button
                onClick={dialog.onOpen}
                mr={3}
                colorScheme="red"
                variant="outline"
            >
                Delete
            </Button>

            <Confirm
                onConfirm={props.onDelete}
                isOpen={dialog.isOpen}
                onClose={dialog.onClose}
                title="Delete Bookshelf"
                content="This operation cannot be undone. Are you sure?"
                confirmButtonText="Yes, delete" />
        </>
    );
};

export default DeleteWithConfirm;
