import {
    FormControl,
    FormLabel,
    Input,
} from "@chakra-ui/react";
import { State } from "@hookstate/core";
import React, { FC, RefObject } from "react";
import useLocalization from "../../hooks/useLocalization";
import { IBookEditState } from "../../types";

interface IProps {
  state: State<IBookEditState>;
  initialFocusRef?: RefObject<HTMLInputElement>;
}

const EditTitle: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const { state, initialFocusRef } = props;

    return (
        <FormControl>
            <FormLabel htmlFor="title">
                {uiText.get("pub-edit-dialog.pub.title.label")}
            </FormLabel>
            <Input
                id="title"
                ref={initialFocusRef}
                onChange={(e) => state.name.set(e.target.value)}
                value={state.name.get()}
                minLength={5}
                maxLength={100}
                placeholder={uiText.get("pub-edit-dialog.pub.title.placeholder")}
            />
        </FormControl>
    );
};

export default EditTitle;
