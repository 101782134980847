import { IMeta, InstanceMetaKey, MetaCollection } from "../types";
import configData from "../../config";
import { APIBase } from "./APIBase";

export class MetaAPI extends APIBase {
    getMeta(): Promise<MetaCollection> {
        let url = configData.timeStoryAPIRootUrl + configData.meta.baseUrl;

        return new Promise((resolve, reject) => {
            this.get(url)
                .then((response) => {
                    const meta: MetaCollection = {};

                    for (let record of response.data as IMeta[]) {
                        meta[record.key] = record.value;
                    }

                    resolve(meta);
                })
                .catch(e => reject(e));
        });
    }

    setMeta(key: InstanceMetaKey, value: string|null) {
        let url = configData.timeStoryAPIRootUrl + configData.meta.baseUrl;

        return new Promise(resolve => {
            this.put(url, [{ key, value }])
                .then((response) => resolve(response.data));
        });
    }

    setImageBox(boxName: string, width: number, height: number) {
        let url = configData.timeStoryAPIRootUrl +
            configData.meta.baseUrl +
            configData.meta.imageBox +
            "/" + encodeURIComponent(boxName);

        return new Promise<void>(resolve => {
            this.put(url, { width, height })
                .then(() => resolve());
        });
    }
}
