import React, { FC, useCallback } from "react";
import { Spinner, Text } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { AddIcon } from "@chakra-ui/icons";
import useLocalization from "../../hooks/useLocalization";
import { getFile } from "../../utils";
import { useHookstate } from "@hookstate/core";
import useApi from "../../hooks/useApi";
import { waitForConversion } from "../Bookshelf/polling";
import useUser from "../../hooks/useUser";
import { IBook, INewBook } from "../../types";

interface IProps {
    book: IBook;
}

const UploadNewVersion: FC<IProps> = (props: IProps) => {
    const { uiText, uiToast, errorToast } = useLocalization();
    const api = useApi();
    const user = useUser();

    const upload = useHookstate<INewBook>({
        status: "NotInProgress",
        name: null
    });

    const onDrop = useCallback(acceptedFiles => {
        const file = getFile(acceptedFiles, () => {
            errorToast(uiText.get("upload.tooManyFiles.description"));
        });

        if (!file) return;

        upload.file.set(file);
        upload.name.set(null);
        upload.totalSize.set(undefined);
        upload.transferred.set(undefined);
        upload.status.set("Uploading");

        api.material().uploadMaterial(acceptedFiles[0], (current, total) => {
            upload.totalSize.set(total);
            upload.transferred.set(current);
        })
            .then(material => {
                upload.materialId.set(material.id);
                upload.status.set(material.status);

                uiToast(
                    "info",
                    "pub-edit-dialog.pub.newVersionUploaded.title",
                    "pub-edit-dialog.pub.newVersionUploaded.content"
                );

                waitForConversion(user.get(), material, upload).then(m => {
                    upload.file.set(undefined);

                    if (m != null) {
                        api.book().modifyBook(props.book.id, {
                            ...props.book,
                            materialId: m.id
                        }).then(() => {
                            uiToast(
                                "success",
                                "pub-edit-dialog.pub.newVersionReady.title",
                                "pub-edit-dialog.pub.newVersionReady.content"
                            );
                        }).catch(e => {
                            errorToast(e);
                        });
                    }
                });
            })
            .catch((error) => {
                errorToast(error);
                upload.status.set("NotInProgress");
            });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "application/vnd.openxmlformats": [".docx"]
        },
        onDrop
    });

    return (
        <div {...getRootProps()} style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            marginRight: "20px",
            border: "dashed white 1px",
            borderRadius: "6px"
        }}>
            <input {...getInputProps()} />
            <Text as="div" px="16px" opacity={upload.file.get() ? .5 : 1}>
                {upload.file.get() ? <Spinner mr="8px" size="sm" />
                    : <AddIcon mr="8px" />} {uiText.get("pub-edit-dialog.pub.newVersionButton")}
            </Text>
        </div>
    );
};

export default UploadNewVersion;
