import React, { FC, useEffect } from "react";
import { useState } from "@hookstate/core";
import { Accordion, Box, Button, useDisclosure } from "@chakra-ui/react";
import BookshelfElement from "./BookshelfElement";
import { bookshelfState } from "../../../state";
import EditShelfDialog from "./EditShelfDialog";
import { IModifyBookshelf } from "../../../types";
import { ShelfOperator } from "./ShelfOperator";
import useApi from "../../../hooks/useApi";
import useLocalization from "../../../hooks/useLocalization";

const BookshelvesTab: FC = () => {
    const { uiText } = useLocalization();
    const api = useApi();

    const state = useState(bookshelfState);
    const editedShelfIndex = useState<number|undefined>(undefined);
    const editedBookshelf = useState<IModifyBookshelf|undefined>(undefined);
    const editShelfDialog = useDisclosure();

    const { shelves } = state;

    const refresh = async () => {
        const shelves = await api.shelf().getShelves();
        state.shelves.set(shelves);
    };

    useEffect(() => {
        refresh();
    }, []);

    const shelfOperator = new ShelfOperator(api.shelf(), shelves.get(), refresh);
    const editing = Boolean(editedBookshelf.get());

    return (
        <>
            <Accordion my="20px" allowToggle>
                {shelves.map((bookshelf, index) => (
                    <BookshelfElement
                        key={bookshelf.id.get()}
                        bookshelf={bookshelf.get()}
                        allowUp={index > 0}
                        onMoveUp={() => shelfOperator.move(index, index - 1, true)}
                        allowDown={index < shelves.length - 1}
                        onMoveDown={() => shelfOperator.move(index, index + 1, false)}
                        onEdit={() => {
                            editedShelfIndex.set(index);
                            editedBookshelf.set({
                                name: bookshelf.name.get(),
                                isPublic: bookshelf.isPublic.get()
                            });
                            editShelfDialog.onOpen();
                        }} />
                ))}
            </Accordion>

            <Box>
                <Button onClick={editShelfDialog.onOpen}>
                    {uiText.get("admin.bookshelves.addNew")}
                </Button>
            </Box>

            <EditShelfDialog
                title={uiText.get("admin.bookshelves.edit." + (editing ? "title" : "addTitle"))}
                allowDelete={editing && shelves[editedShelfIndex.get() || 0].books.length == 0}
                editedBookshelf={editedBookshelf.get()}
                onSave={async (data) => {
                    const index = editedShelfIndex.get();

                    if (typeof index === "undefined") {
                        await shelfOperator.add(data.name || "Default", Boolean(data.isPublic));
                    }
                    else {
                        await shelfOperator.modify(index, data);
                    }
                }}
                onDelete={async () => {
                    const index = editedShelfIndex.get();
                    if (typeof index !== "undefined") {
                        await shelfOperator.delete(index);
                    }
                }}
                isOpen={editShelfDialog.isOpen}
                onClose={() => {
                    editedBookshelf.set(undefined);
                    editedShelfIndex.set(undefined);
                    editShelfDialog.onClose();
                }} />
        </>
    );
};

export default BookshelvesTab;
