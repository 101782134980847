import React, { FC } from "react";
import { Stack, Link, SkeletonText } from "@chakra-ui/react";
import { getFooterLinks } from "../../footer";
import { useState } from "@hookstate/core";
import { metaState } from "../../state";

export const Links: FC = () => {
    const meta = useState(metaState);

    if (meta.initializing.get()) {
        return (
            <Stack w="200px">
                <SkeletonText noOfLines={4} spacing={7} />
            </Stack>
        );
    }

    return (
        <Stack>
            {getFooterLinks(meta).map((link, index) => (
                <Link key={index} href={link.url} isExternal>
                    {link.title}
                </Link>
            ))}
        </Stack>
    );
};
