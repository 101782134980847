import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay
} from "@chakra-ui/react";
import React, { createRef, FC } from "react";
import useLocalization from "../hooks/useLocalization";

interface IProps {
    title: string;
    content: string;
    confirmButtonText: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const Confirm: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const cancelRef = createRef<HTMLButtonElement>();

    return (
        <AlertDialog
            isOpen={props.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={props.onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {props.title}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {props.content}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={props.onClose}>
                            {uiText.get("general.cancel")}
                        </Button>
                        <Button colorScheme="red" onClick={() => {
                            props.onClose();
                            props.onConfirm();
                        }} ml={3}>
                            {props.confirmButtonText}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default Confirm;
