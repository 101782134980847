import React, { FC } from "react";
import {
    Box,
    IconButton,
    Input,
    Image,
    InputGroup,
    InputRightElement,
    useColorModeValue
} from "@chakra-ui/react";
import searchIconUrlWhite from "../../../assets/search-icon.svg";
import searchIconUrlBlack from "../../../assets/search-icon-black.svg";
import useLocalization from "../../hooks/useLocalization";
import { useHookstate } from "@hookstate/core";
import { useNavigate } from "react-router-dom";
import { searchState } from "../../state";

interface IProps {
    onSearch?: (q: string) => void;
}

const Search: FC<IProps> = (props: IProps) => {
    const navigate = useNavigate();
    const { uiText } = useLocalization();
    const q = useHookstate(searchState);

    const search = () => {
        const query = q.get();
        if (query.length != 0)
        {
            if (props.onSearch) {
                props.onSearch(query);
            }

            navigate("/search?q=" + encodeURIComponent(query));
        }
    };

    return (
        <Box>
            <InputGroup size="lg">
                <Input
                    value={q.get()}
                    onChange={e => q.set(e.target.value)}
                    onKeyPress={e => {
                        if (e.key == "Enter") {
                            search();
                        }
                    }}
                    w="100%"
                    variant="filled"
                    placeholder={uiText.get("menu.search.placeholder")} />

                <InputRightElement>
                    <IconButton
                        onClick={() => search()}
                        backgroundColor="transparent"
                        size="sm"
                        icon={(
                            <Image
                                src={useColorModeValue(searchIconUrlBlack, searchIconUrlWhite)} />
                        )}
                        aria-label="Search" />
                </InputRightElement>
            </InputGroup>
        </Box>
    );
};

export default Search;
