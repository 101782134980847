import React, { createRef, FC, useEffect } from "react";
import { useHookstate } from "@hookstate/core";
import { Flex, Button, FormControl, FormLabel, Input, FormHelperText } from "@chakra-ui/react";
import useLocalization from "../../hooks/useLocalization";

interface IProps {
    onDone: () => void;
}

export const PasswordResetForm: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();

    const email = useHookstate("");

    const focusRef = createRef<HTMLInputElement>();

    useEffect(() => {
        focusRef.current?.focus();
    }, []);

    return (
        <form onSubmit={e => {
            e.preventDefault();
            props.onDone();
        }}>
            <FormControl>
                <FormLabel htmlFor="email">
                    {uiText.get("signIn.passwordReset.email.label")}
                </FormLabel>

                <Input
                    ref={focusRef}
                    id="email"
                    type="email"
                    placeholder={uiText.get("signIn.passwordReset.email.placeholder")}
                    value={email.get()}
                    onChange={e => email.set(e.target.value)} />

                <FormHelperText>
                    {uiText.get("signIn.passwordReset.email.helperText")}
                </FormHelperText>
            </FormControl>

            <Flex mt="30px" gap="12px">
                <Button
                    type="submit"
                    w="100%"
                    colorScheme="blue"
                    disabled={!email.get()}
                >
                    {uiText.get("signIn.passwordReset.submit")}
                </Button>

                <Button
                    onClick={() => {
                        email.set("");
                        props.onDone();
                    }}
                    w="100%"
                    colorScheme="blue"
                    variant="ghost"
                >
                    {uiText.get("signIn.passwordReset.cancel")}
                </Button>
            </Flex>
        </form>
    );
};
