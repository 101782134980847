import { State } from "@hookstate/core";
import { IMaterial, INewBook, IUser } from "../../types";
import { MaterialAPI } from "../../apiProxy/material";

export function waitForConversion(
    user: IUser,
    material: IMaterial,
    upload: State<INewBook>
): Promise<IMaterial|null>
{
    return new Promise(resolve => {
        const work = () => {
            poll(user, material, upload).then(m => {
                if (m.status === "ConversionEndedSuccesfully") {
                    resolve(m);
                }
                else if (m.status.indexOf("Ended") === -1) {
                    work();
                }
                else {
                    resolve(null);
                }
            });
        };

        work();
    });
}

function poll(
    user: IUser,
    material: IMaterial,
    upload: State<INewBook>
): Promise<IMaterial>
{
    const api = new MaterialAPI(user);

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            api.getMaterial(material.id).then(m => {
                upload.status.set(m.status);

                if (m.name) {
                    upload.name.set(m.name);
                }

                resolve(m);
            }).catch(e => reject(e));
        }, 1000);
    });
}
