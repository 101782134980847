/* eslint-disable no-console */
import { MetaAPI } from "./apiProxy/meta";
import { InstanceMetaKey, IUser } from "./types";

export function setupBrowserConsoleTools() {
    (window as any).ts = {
        showMeta,
        setMeta: _setMeta,
        setImageBox
    };

    console.info("Console tools initialized.");
}

async function showMeta() {
    const api = new MetaAPI(getUser());
    const meta = await api.getMeta();
    console.table(meta);
}

function _setMeta(key: InstanceMetaKey, value: string|null) {
    const api = new MetaAPI(getUser());
    api.setMeta(key, value).then(() => {
        console.info("Meta value set.");
    });
}

function setImageBox(boxName: string, width: number, height: number) {
    const api = new MetaAPI(getUser());
    api.setImageBox(boxName, width, height).then(() => {
        console.info("Image box set.");
    });
}

function getUser(): IUser {
    return {
        locale: "en",
        token: localStorage.getItem("token") || ""
    };
}
