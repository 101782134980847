import React, { createRef, FC, useEffect } from "react";
import { Button, Flex, Spinner } from "@chakra-ui/react";
import useLocalization from "../../hooks/useLocalization";
import { useHookstate } from "@hookstate/core";
import { signIn } from "../../apiProxy/user";
import { IUser } from "../../types";
import { PasswordResetLink } from "./PasswordResetLink";
import { UsernameInput } from "./UsernameInput";
import { PasswordInput } from "./PasswordInput";
import { bookshelfState } from "../../state";
import useApi from "../../hooks/useApi";

interface IProps {
    onSuccess: (user: IUser) => void;
    onCancel: () => void;
    onPasswordReset: () => void;
}

export const SignInForm: FC<IProps> = (props: IProps) => {
    const { uiText } = useLocalization();
    const api = useApi();

    const state = useHookstate(bookshelfState);

    const username = useHookstate("");
    const password = useHookstate("");
    const error = useHookstate<string|undefined>(undefined);
    const working = useHookstate(false);

    const focusRef = createRef<HTMLInputElement>();

    useEffect(() => {
        focusRef.current?.focus();
    }, []);

    const afterLogin = async () => {
        working.set(false);

        const shelves = await api.shelf().getShelves();
        state.shelves.set(shelves);
    };

    return (
        <form onSubmit={e => {
            e.preventDefault();

            if (username.get() && password.get()) {
                working.set(true);

                signIn(username.get(), password.get())
                    .then(result => {
                        password.set("");
                        error.set(result.error);

                        if (!result.error && result.user) {
                            props.onSuccess(result.user);
                            username.set("");
                        }

                        afterLogin();
                    })
                    .catch(afterLogin);
            }
        }}>
            <UsernameInput
                state={username}
                focusRef={focusRef}
                disabled={working.get()}
                inError={Boolean(error.get())} />

            <PasswordInput
                state={password}
                disabled={working.get()}
                error={error.get()} />

            <Flex mt="30px" gap="12px">
                <Button
                    disabled={working.get()}
                    type="submit"
                    w="100%"
                    colorScheme="blue"
                >
                    {working.get() ? <Spinner /> : uiText.get("signIn.form.submit")}
                </Button>

                <Button
                    onClick={() => {
                        username.set("");
                        password.set("");
                        error.set(undefined);
                        props.onCancel();
                    }}
                    w="100%"
                    colorScheme="blue"
                    variant="ghost"
                >
                    {uiText.get("signIn.form.cancel")}
                </Button>
            </Flex>

            {error.get() && <PasswordResetLink onClick={() => {
                props.onPasswordReset();
            }} />}
        </form>
    );
};
