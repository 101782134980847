import React, { FC } from "react";
import { Box, Heading, Link, Skeleton } from "@chakra-ui/react";
import { COVER_ASPECT_RATIO } from "../../constants";
import { IBook } from "../../types";
import { metaState } from "../../state";
import { useState } from "@hookstate/core";
import { BookPropReader } from "../BookPropReader";

const COVER_HEIGHT = 500;
const W = COVER_HEIGHT * COVER_ASPECT_RATIO;

interface IProps {
    book?: IBook;
}

const Cover: FC<IProps> = (props: IProps) => {
    const { initializing: skeletonMode } = useState(metaState);

    let coverUrl = "";

    if (props.book) {
        const book = new BookPropReader(props.book);
        coverUrl = book.getCoverImageUrl();
    }

    const readUrl = props.book?.material.publicationURL || "";

    return (
        <Box position="relative" minH="45vh" overflow="hidden">
            <Box
                w={`${W}px`}
                h={`${COVER_HEIGHT}px`}
                position="absolute"
                left={["5%", "20%"]}
                top={["10%", "10%", "20%"]}
                transform="rotate(10deg)"
                backgroundColor="maguerite.200"
                backgroundImage={`url(${coverUrl})`}
                backgroundSize="cover"
                border="solid white 1px"
                borderRadius={2}
                boxShadow="rgba(149, 157, 165, 0.2) 0px 12px 36px"
            >
                <Box backgroundColor="rgba(0, 0, 0, 0.5)" p="20px">
                    <Link isExternal href={readUrl}>
                        <Heading as="div" fontSize="4xl">
                            {skeletonMode.get() ? (<>
                                <Skeleton height="30px" my={3} />
                                <Skeleton height="30px" />
                            </>) : props.book?.name}
                        </Heading>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default Cover;
