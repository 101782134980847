import en from "../../assets/en.json";
import { UIDictionary } from "../UIText";

export function getUIDictionary(lang = "en"): Promise<UIDictionary> {
    return new Promise((resolve, reject) => {
        if (lang != "en") {
            reject();
            return;
        }

        const dictionary: UIDictionary = {
            "#ready": "true"
        };

        en.forEach(item => {
            dictionary[item.key] = item.text;
        });

        resolve(dictionary);
    });
}
