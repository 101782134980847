import React, { createRef, FC } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { IBookshelf } from "../../types";
import Book from "./Book";
import { State } from "@hookstate/core";
import useUser from "../../hooks/useUser";
import NewBook from "./NewBook";

interface IProps {
    shelf: State<IBookshelf>;
    scrollRef: ReturnType<typeof createRef<HTMLDivElement>>;
}

const BookList: FC<IProps> = (props: IProps) => {
    const user = useUser();

    return (
        <Box overflow={["auto", "hidden"]} ref={props.scrollRef}>
            <Flex
                flexWrap="nowrap"
                justifyContent={["normal", "left"]}
                gap="40px"
                mt="10px"
            >
                {user.isSignedIn() ? (
                    <NewBook shelf={props.shelf} />
                ) : null}

                {props.shelf.books.map(book => (
                    <Book
                        key={book.id.get()}
                        state={book} />
                ))}
            </Flex>
        </Box>
    );
};

export default BookList;
