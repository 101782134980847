import React, { FC } from "react";
import { Box, BoxProps, Container, useColorMode } from "@chakra-ui/react";
import { IParent } from "../types";

interface IProps extends IParent<React.ReactNode> {
    fullWidth?: boolean;
    inverted?: boolean;
    fixed?: boolean;
}

const INVERTED_PROPS: BoxProps = {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "cyan.100"
};

export const LayoutSection: FC<IProps> = (props: IProps) => {
    const { colorMode } = useColorMode();

    const content = props.fullWidth ? (
        props.children
    ) : (
        <Container>
            {props.children}
        </Container>
    );

    return (
        <Box {...getBoxProps(props, colorMode)}>
            {content}
        </Box>
    );
};

function getBoxProps(props: IProps, colorMode: string): BoxProps {
    const fixedProps: BoxProps = props.fixed ? {
        position: "fixed",
        width: "100%",
        backgroundColor: (colorMode == "light" ? "white" : "rgb(26, 32, 44)"),
        zIndex: 1000,
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
    } : {};

    return {
        ...fixedProps,
        ...(props.inverted ? INVERTED_PROPS : {})
    };
}
